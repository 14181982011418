import {
  Box,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useCalculateAllTotal } from "hooks/hook";
import { StyledTableRow } from "layouts/main/MainPageStyle";
import { StyledTableCell } from "layouts/main/MainPageStyle";
import { tableBoxShawdow } from "layouts/vendorReport/VendorReportPageStyle";
import React from "react";

const MainPageDetailsComparisonTable = ({
  currentNLastYearStoreDetailInfo,
}) => {
  const comparisonTable = ["CATEGORY", "매출액", "전년매출액", "전년 대비"];
  const transformedData = currentNLastYearStoreDetailInfo.reduce(
    (acc, { total, ptName, lastYearTotal }) => {
      acc[ptName] = {
        total,
        lastYearTotal,
        comparedToLastYear: total - lastYearTotal,
      };
      return acc;
    },
    {}
  );

  const { calculatedTotals } = useCalculateAllTotal({
    dailyTotalSales: transformedData,
  });

  return (
    <Box>
      <TableContainer
        component={Paper}
        sx={{
          ...tableBoxShawdow,
          ...comparisonTable,
        }}
      >
        <Table
          sx={{
            minWidth: 320,
            "& th, & td": {
              fontSize: { xs: "12px", sm: "14px", md: "16px" },
              padding: { xs: "6px", sm: "8px", md: "12px" },
            },
          }}
        >
          <TableHead>
            <TableRow>
              {comparisonTable.map((title, index) => (
                <StyledTableCell
                  key={title + index}
                  align="center"
                  isFixed={title === comparisonTable[0]}
                >
                  {title}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(transformedData).map(([key, value]) => (
              <StyledTableRow key={key + value}>
                <StyledTableCell align="center" isFixed={true}>
                  {key}
                </StyledTableCell>
                <StyledTableCell align="center">
                  $
                  {value.total.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </StyledTableCell>
                <StyledTableCell align="center">
                  $
                  {value.lastYearTotal.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </StyledTableCell>
                <StyledTableCell align="center">
                  $
                  {value.comparedToLastYear.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </StyledTableCell>
              </StyledTableRow>
            ))}
            <TableRow>
              {/* {calculatedTotals && (
                <>
                  <StyledTableCell align="center" isFixed={true}>
                    합계
                  </StyledTableCell>
                  {Object.entries(calculatedTotals).map(([key, value]) => (
                    <StyledTableCell align="center">
                      ${value.toFixed(2)}
                    </StyledTableCell>
                  ))}
                </>
              )} */}
              {calculatedTotals && (
                <>
                  <StyledTableCell align="center" isFixed={true}>
                    합계
                  </StyledTableCell>

                  {Object.entries(calculatedTotals).map(([key, value]) => (
                    <StyledTableCell key={key + value} align="center">
                      $
                      {value.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </StyledTableCell>
                  ))}
                </>
              )}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default MainPageDetailsComparisonTable;
