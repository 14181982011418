import {
  Box,
  Checkbox,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useCallback, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import OrderDetailComponent from "./OrderDetailComponent";
import { smallToggleCheckbox, useLargeToggleCheckbox } from "./OrderPageUtils";
const OrderPageExtendedTable = React.memo((props) => {
  const { values, currentOrderTable, setCurrentOrderTable, titleIndex } = props;
  const [extendTableOpen, setExtendTableOpen] = useState(false);

  const largeToggleCheckbox = useLargeToggleCheckbox({ setCurrentOrderTable });

  //랜더링을 최소화 하기위한 로직(useCallback을 사용)
  const orderCategoryComponent = useCallback(() => {
    return (
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setExtendTableOpen(!extendTableOpen)}
          >
            {extendTableOpen ? (
              <KeyboardArrowUpIcon />
            ) : (
              <KeyboardArrowDownIcon />
            )}
          </IconButton>
        </TableCell>

        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }}>
          <Checkbox
            checked={values.select}
            onChange={() => largeToggleCheckbox(titleIndex)}
          />
        </TableCell>

        <TableCell>{values.pName}</TableCell>
        <TableCell align="right" sx={{ marginLeft: "200px" }}>
          {values.totalQuantity}
        </TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        {/* <TableCell>{values.totalAmount.toFixed(2)}</TableCell> */}
      </TableRow>
    );
  }, [values, largeToggleCheckbox, extendTableOpen, titleIndex]);

  return (
    <>
      {orderCategoryComponent()}

      <TableRow>
        <TableCell sx={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={extendTableOpen} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography
                variant="h6"
                gutterBottom
                component="div"
              ></Typography>
              <Table size="small" aria-label="purchases">
                <TableBody>
                  <TableRow>
                    <TableCell align="center" sx={{ fontSize: "12px" }}>
                      선택
                    </TableCell>
                    <TableCell align="left" sx={{ fontSize: "12px" }}>
                      밴더아이템코드
                    </TableCell>
                    <TableCell sx={{ fontSize: "12px" }}>UPC 코드</TableCell>
                    <TableCell sx={{ fontSize: "12px" }}>상품명</TableCell>
                    <TableCell align="center" sx={{ fontSize: "12px" }}>
                      판매단위
                    </TableCell>
                    <TableCell align="right" sx={{ fontSize: "12px" }}>
                      주문수량
                    </TableCell>

                    <TableCell></TableCell>
                    {/* <TableCell align="center">단위당 가격</TableCell> */}
                    {/* <TableCell align="center">총 가격 ($)</TableCell> */}
                  </TableRow>

                  <OrderDetailComponent
                    categoryDetail={values}
                    titleIndex={titleIndex}
                    setCurrentOrderTable={setCurrentOrderTable}
                  />
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
});

export default OrderPageExtendedTable;

{
  /* <TableCell align="right">{group.totalQ}</TableCell> */
}

{
  /* <TableCell align="center">
                          <NumberCounter
                            currentNum={group.totalQ}
                            currentOrderTable={currentOrderTable}
                            setCurrentOrderTable={setCurrentOrderTable}
                            index={index}
                          ></NumberCounter>
                        </TableCell>
                        <TableCell align="center">
                          <CurrentPriceTextFiled
                            currentOrderTable={currentOrderTable}
                            setCurrentOrderTable={setCurrentOrderTable}
                            // currentPrice={row.currentPrice}

                            index={index}
                          />
                        </TableCell> */
}

{
  /* <TableCell align="center">
                          {group.totalA.toFixed(2)}
                        </TableCell> */
}
