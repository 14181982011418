import { createChartDataForm } from "utils/ChartOption";
import { calculateIncreaseRateVersionTwo } from "utils/utils";
import { getOneYBeforeSameDay } from "utils/utils";

export const reformedDailyDetailsPageChartData = (props) => {
  const { dailyDetailsData } = props;

  const storeCategorires = Object.values(dailyDetailsData).map(
    (item) => item["ptName"]
  );

  const categories = ["현재날짜총합", "전년날짜총합"];
  const currentYTotal = Object.values(dailyDetailsData).map(
    (item) => item["currentYear"]
  );
  const prevYTotal = Object.values(dailyDetailsData).map(
    (item) => item["lastYear"]
  );
  const chartData = createChartDataForm(
    storeCategorires,
    categories,
    currentYTotal,
    prevYTotal
  );
  return chartData;
};

export const reformedDailyDetailsTableData = (props) => {
  const { dailyDetailsData, selectedMonth, gapSDateToEDate } = props;

  // const { storeCategories, initialData } = initializeTableData(
  //   dailyDetailsData,
  //   selectedMonth
  // );
  const { storeCategories, initialData } = initializeTableData(
    dailyDetailsData,
    selectedMonth
  );
  const tableDataList = [...initialData];
  // const storeCategorires = Object.values(dailyDetailsData[0]).map(
  //   (item) => item["ptName"]
  // );
  // tableDataList.push(["CATEGORY", ...storeCategorires]);

  // const lastYear = Object.values(dailyDetailsData[0]).map(
  //   (item) => item["lastYear"]
  // );
  // tableDataList.push([`${selectedMonth.year() - 1 + " Total"}`, ...lastYear]);

  // const currentYear = Object.values(dailyDetailsData[0]).map(
  //   (item) => item["currentYear"]
  // );
  // tableDataList.push([`${selectedMonth.year() + " Total"}`, ...currentYear]);

  // const checkMeatIndex = storeCategorires.indexOf("MEAT");
  // const checkMeatPurchasePrice = dailyDetailsData[3].filter(
  //   (item) => item.dp_category === "Meat"
  // );

  // const purchaseMeatList = [`${selectedMonth.year() + " 매입"}`];

  // for (let i = 0; i < storeCategorires.length; i++) {
  //   if (i === checkMeatIndex) {
  //     purchaseMeatList.push(checkMeatPurchasePrice[0]["totalAmount"]);
  //   } else {
  //     purchaseMeatList.push("");
  //   }
  // }

  // const yearIncreaseRate = ["증감율"];

  // for (let i = 0; i < currentYear.length; i++) {
  //   const calculatedRate = calculateIncreaseRateVersionTwo({
  //     selectedDatePrice: currentYear[i],
  //     compareDatePrice: lastYear[i],
  //   });
  //   yearIncreaseRate.push(parseFloat(calculatedRate));
  // }

  // tableDataList.push(yearIncreaseRate);
  // tableDataList.push(purchaseMeatList);

  const currentDayStart = selectedMonth.startOf("month");

  const compareDayStart = getOneYBeforeSameDay(currentDayStart);

  const dateObject = new Date(compareDayStart);

  // const year = dateObject.getFullYear();
  // const month = String(dateObject.getMonth() + 1).padStart(2, "0");
  // const day = String(dateObject.getDate()).padStart(2, "0");

  // let lastYearformattedDate = `${year}-${month}-${day}`;
  let lastYearformattedDate = formatDate(dateObject);
  let currentYearForttedDate = currentDayStart.format("YYYY-MM-DD");
  const { currentMonthMap, lastYearMonthMap } =
    createDataMaps(dailyDetailsData);
  // const currentMonthMap = new Map();
  // const lastYearMonthMap = new Map();
  // for (const item of dailyDetailsData[1]) {
  //   const key = `${item.date.substring(0, 10)}_${item.ptName}`;

  //   currentMonthMap.set(key, item);
  // }
  // for (const item of dailyDetailsData[2]) {
  //   const key = `${item.date.substring(0, 10)}_${item.ptName}`;

  //   lastYearMonthMap.set(key, item);
  // }

  for (let i = 0; i <= gapSDateToEDate; i++) {
    // const tempCurrentYear = [currentYearForttedDate];
    // const tempLastYear = [lastYearformattedDate];
    // const tempIncreaseRate = ["증감율"];
    // for (let j = 0; j < storeCategories.length; j++) {
    //   const currentKey = currentYearForttedDate + "_" + storeCategories[j];
    //   const lastYearKey = lastYearformattedDate + "_" + storeCategories[j];

    //   if (currentMonthMap.get(`${currentKey}`)) {
    //     tempCurrentYear.push(currentMonthMap.get(`${currentKey}`)["total"]);
    //   } else {
    //     tempCurrentYear.push(0);
    //   }
    //   if (lastYearMonthMap.get(`${lastYearKey}`)) {
    //     tempLastYear.push(lastYearMonthMap.get(`${lastYearKey}`)["total"]);
    //   } else {
    //     tempLastYear.push(0);
    //   }

    //   const calculatedrate = calculateIncreaseRateVersionTwo({
    //     selectedDatePrice: currentMonthMap.get(`${currentKey}`)?.["total"] || 0,
    //     compareDatePrice:
    //       lastYearMonthMap.get(`${lastYearKey}`)?.["total"] || 0,
    //   });

    //   tempIncreaseRate.push(parseFloat(calculatedrate));
    // }
    const { tempCurrentYear, tempLastYear, tempIncreaseRate } =
      getTempDataForCategory(
        currentYearForttedDate,
        lastYearformattedDate,
        storeCategories,
        currentMonthMap,
        lastYearMonthMap
      );

    // console.log(lastYearMonthMap);
    tableDataList.push(
      [...tempLastYear],
      [...tempCurrentYear],
      [...tempIncreaseRate]
    );
    // tableDataList.push();
    // tableDataList.push();
    tempCurrentYear.length = 0;
    tempLastYear.length = 0;
    tempIncreaseRate.length = 0;
    const tempCurrentDate = new Date(currentYearForttedDate);
    const tempLastYearDate = new Date(lastYearformattedDate);
    tempCurrentDate.setDate(tempCurrentDate.getDate() + 1);
    tempLastYearDate.setDate(tempLastYearDate.getDate() + 1);

    currentYearForttedDate = tempCurrentDate.toISOString().split("T")[0];
    lastYearformattedDate = tempLastYearDate.toISOString().split("T")[0];
  }
  // console.log(tableDataList, "lastTableDataList");
  return tableDataList;
};

export const formatDate = (timestamp) => {
  const date = new Date(timestamp);
  if (isNaN(date.getTime())) {
    throw new Error("Invalid date provided to formatDate");
  }
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  let lastYearformattedDate = `${year}-${month}-${day}`;
  return lastYearformattedDate;
};

export const createDataMaps = (dailyDetailsData) => {
  const currentMonthMap = new Map();
  const lastYearMonthMap = new Map();

  for (const item of dailyDetailsData[1]) {
    const key = `${item.date.substring(0, 10)}_${item.ptName}`;
    currentMonthMap.set(key, item);
  }

  for (const item of dailyDetailsData[2]) {
    const key = `${item.date.substring(0, 10)}_${item.ptName}`;
    lastYearMonthMap.set(key, item);
  }

  return { currentMonthMap, lastYearMonthMap };
};

const initializeTableData = (dailyDetailsData, selectedMonth) => {
  const storeCategories = Object.values(dailyDetailsData[0]).map(
    (item) => item["ptName"]
  );
  const lastYear = Object.values(dailyDetailsData[0]).map(
    (item) => item["lastYear"]
  );
  const currentYear = Object.values(dailyDetailsData[0]).map(
    (item) => item["currentYear"]
  );

  const checkMeatIndex = storeCategories?.indexOf("MEAT");
  const checkMeatPurchasePrice = dailyDetailsData[3]?.filter(
    (item) => item.dp_category === "Meat"
  );

  const purchaseMeatList = [`${selectedMonth.year() + " 매입"}`];

  for (let i = 0; i < storeCategories.length; i++) {
    purchaseMeatList.push(
      i === checkMeatIndex
        ? checkMeatPurchasePrice?.[0]?.["totalAmount"] || ""
        : ""
    );
  }

  const yearIncreaseRate = ["증감율"];
  for (let i = 0; i < currentYear.length; i++) {
    yearIncreaseRate.push(
      parseFloat(calculateRate(currentYear[i], lastYear[i]))
    );
  }

  return {
    storeCategories,
    initialData: [
      ["CATEGORY", ...storeCategories],
      [`${selectedMonth.year() - 1 + " Total"}`, ...lastYear],
      [`${selectedMonth.year() + " Total"}`, ...currentYear],
      yearIncreaseRate,
      purchaseMeatList,
    ],
  };
};

const calculateRate = (current, last) => {
  return calculateIncreaseRateVersionTwo({
    selectedDatePrice: current || 0,
    compareDatePrice: last || 0,
  });
};

const incrementDate = (dateString) => {
  const date = new Date(dateString);
  date.setDate(date.getDate() + 1);
  return formatDate(date.getTime());
};

const getTempDataForCategory = (
  currentYearForttedDate,
  lastYearformattedDate,
  storeCategories,
  currentMonthMap,
  lastYearMonthMap
) => {
  // console.log(currentYearForttedDate, lastYearformattedDate);
  const tempCurrentYear = [currentYearForttedDate];
  const tempLastYear = [lastYearformattedDate];
  const tempIncreaseRate = ["증감율"];

  for (let j = 0; j < storeCategories.length; j++) {
    const currentKey = currentYearForttedDate + "_" + storeCategories[j];
    const lastYearKey = lastYearformattedDate + "_" + storeCategories[j];

    // currentYear 데이터 추가
    tempCurrentYear.push(
      currentMonthMap.get(currentKey)
        ? currentMonthMap.get(currentKey)["total"]
        : 0
    );

    // lastYear 데이터 추가
    tempLastYear.push(
      lastYearMonthMap.get(lastYearKey)
        ? lastYearMonthMap.get(lastYearKey)["total"]
        : 0
    );

    // 증가율 계산
    const calculatedrate = calculateIncreaseRateVersionTwo({
      selectedDatePrice: currentMonthMap.get(currentKey)?.["total"] || 0,
      compareDatePrice: lastYearMonthMap.get(lastYearKey)?.["total"] || 0,
    });

    tempIncreaseRate.push(parseFloat(calculatedrate));
  }

  return { tempCurrentYear, tempLastYear, tempIncreaseRate };
};

const addOneMoreDate = (props) => {
  const { selectedDate } = props;
  const tempCurrentDate = new Date(selectedDate);
  const reformedDate = tempCurrentDate.toISOString().split("T")[0];
  return reformedDate;
};
