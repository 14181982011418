import { STORE } from "globalApi/EnumType";
import { createChartDataForm } from "utils/ChartOption";
import { calculateIncreaseRateVersionTwo } from "utils/utils";

export const reformedMonthChartData = (props) => {
  const { monthData } = props;

  const storeCategorires = Object.values(monthData).map(
    (item) => item["ptName"]
  );

  const categories = ["현재날짜총합", "전년날짜총합"];
  const currentYTotal = Object.values(monthData).map(
    (item) => item["currentYear"]
  );
  const prevYTotal = Object.values(monthData).map((item) => item["lastYear"]);

  const chartData = createChartDataForm(
    storeCategorires,
    categories,
    currentYTotal,
    prevYTotal
  );
  return chartData;
};

export const reformedMonthTableData = (props) => {
  const { monthData, selectedDate, gapSDateToEDate } = props;
  const initalDataTableSet = inintalDataTableSet({ monthData, selectedDate });
  const tableDataList = [...initalDataTableSet];
  // const storeCategorires = Object.values(monthData[0]).map(
  //   (item) => item["ptName"]
  // );

  // tableDataList.push(["", "부서", ...storeCategorires]);
  // const lastYear = Object.values(monthData[0]).map((item) => item["lastYear"]);
  // tableDataList.push(["", `${selectedDate.year() - 1 + " 총합"}`, ...lastYear]);

  // const currentYear = Object.values(monthData[0]).map(
  //   (item) => item["currentYear"]
  // );

  // tableDataList.push([
  //   "전지점",
  //   `${selectedDate.year() + " 총합"}`,
  //   ...currentYear,
  // ]);
  // const yearIncreaseRate = ["", "증감율"];
  // for (let i = 0; i < currentYear.length; i++) {
  //   const calculatedRate = calculateIncreaseRateVersionTwo({
  //     selectedDatePrice: currentYear[i],
  //     compareDatePrice: lastYear[i],
  //   });
  //   yearIncreaseRate.push(parseFloat(calculatedRate));
  //   // console.log(calculatedRate, "calculated");
  // }
  // tableDataList.push(yearIncreaseRate);

  //스토어 들어갈 데이터들 initalizing 하는 작업
  let storeN = monthData[1][0]["store"];

  const tempCategory = ["", "부서"];
  const tempLastYearStoreInfoList = [
    `${STORE["61"][0]}`,
    `${selectedDate.year() - 1} 종합`,
  ];
  const tempCurrentYearSotreInfoList = ["", `${selectedDate.year()} 종합`];
  const tempIncreaseList = ["", "증감율"];

  for (let i = 0; i < monthData[1].length; i++) {
    if (storeN === monthData[1][i]["store"]) {
      sameStoreInfo({
        tempCategory,
        monthData,
        tempLastYearStoreInfoList,
        tempCurrentYearSotreInfoList,
        tempIncreaseList,
        index: i,
      });
      // tempCategory.push(monthData[1][i]["ptName"]);
      // tempLastYearStoreInfoList.push(monthData[1][i]["lastYear"]);
      // tempCurrentYearSotreInfoList.push(monthData[1][i]["currentYear"]);
      // const calculatedrate = calculateIncreaseRateVersionTwo({
      //   selectedDatePrice: monthData[1][i]["currentYear"] || 0,
      //   compareDatePrice: monthData[1][i]["lastYear"] || 0,
      // });

      // tempIncreaseList.push(parseFloat(calculatedrate));
    } else {
      storeN = monthData[1][i]["store"];
      handleNewStore(
        tableDataList,
        tempCategory,
        tempLastYearStoreInfoList,
        tempCurrentYearSotreInfoList,
        tempIncreaseList,
        monthData,
        selectedDate,
        storeN
      );
      // tableDataList.push(
      //   [...tempCategory],
      //   [...tempLastYearStoreInfoList],
      //   [...tempCurrentYearSotreInfoList],
      //   [...tempIncreaseList]
      // );

      // tempCategory.length = 0;
      // tempLastYearStoreInfoList.length = 0;
      // tempCurrentYearSotreInfoList.length = 0;
      // tempIncreaseList.length = 0;
      // tempCategory.push("", "부서");

      // tempLastYearStoreInfoList.push(
      //   `${STORE[monthData[1][i]["store"]][0]}`,
      //   `${selectedDate.year() - 1} 종합`
      // );
      // tempCurrentYearSotreInfoList.push("", ` ${selectedDate.year()} 종합`);
      // tempIncreaseList.push("", "증감율");
      sameStoreInfo({
        tempCategory,
        monthData,
        tempLastYearStoreInfoList,
        tempCurrentYearSotreInfoList,
        tempIncreaseList,
        index: i,
      });
      // tempCategory.push(monthData[1][i]["ptName"]);
      // tempLastYearStoreInfoList.push(monthData[1][i]["lastYear"]);
      // tempCurrentYearSotreInfoList.push(monthData[1][i]["currentYear"]);
      // const calculatedrate = calculateIncreaseRateVersionTwo({
      //   selectedDatePrice: monthData[1][i]["currentYear"] || 0,
      //   compareDatePrice: monthData[1][i]["lastYear"] || 0,
      // });
      // tempIncreaseList.push(parseFloat(calculatedrate));
    }
  }
  //스토어가 바뀌고 마지막에 임시로 들어가있는 데이터가 tableDatalist 에 들어가 있어서 추가해주는 작업
  tableDataList.push(
    [...tempCategory],
    [...tempLastYearStoreInfoList],
    [...tempCurrentYearSotreInfoList],
    [...tempIncreaseList]
  );
  // tableDataList.push();
  // tableDataList.push();
  // tableDataList.push();
  // console.log(tableDataList, "tableDataList");
  return tableDataList;
};

export const inintalDataTableSet = (props) => {
  const { monthData, selectedDate } = props;
  const initialDataList = [];
  const storeCategorires = Object.values(monthData[0]).map(
    (item) => item["ptName"]
  );

  initialDataList.push(["", "부서", ...storeCategorires]);
  const lastYear = Object.values(monthData[0]).map((item) => item["lastYear"]);
  initialDataList.push([
    "",
    `${selectedDate.year() - 1 + " 총합"}`,
    ...lastYear,
  ]);

  const currentYear = Object.values(monthData[0]).map(
    (item) => item["currentYear"]
  );

  initialDataList.push([
    "전지점",
    `${selectedDate.year() + " 총합"}`,
    ...currentYear,
  ]);
  const yearIncreaseRate = ["", "증감율"];
  for (let i = 0; i < currentYear.length; i++) {
    const calculatedRate = calculateIncreaseRateVersionTwo({
      selectedDatePrice: currentYear[i],
      compareDatePrice: lastYear[i],
    });
    yearIncreaseRate.push(parseFloat(calculatedRate));
    // console.log(calculatedRate, "calculated");
  }
  initialDataList.push(yearIncreaseRate);
  // let storeN = monthData[1][0]["store"];

  // const tempCategory = ["", "부서"];
  // const tempLastYearStoreInfoList = [
  //   `${STORE["61"][0]}`,
  //   `${selectedDate.year() - 1} 종합`,
  // ];
  // const tempCurrentYearSotreInfoList = ["", `${selectedDate.year()} 종합`];
  // const tempIncreaseList = ["", "증감율"];

  return initialDataList;
};

export const handleNewStore = (
  tableDataList,
  tempCategory,
  tempLastYearStoreInfoList,
  tempCurrentYearSotreInfoList,
  tempIncreaseList,
  monthData,
  selectedDate,
  storeN
) => {
  // storeN = monthData[1][i]["store"];
  tableDataList.push(
    [...tempCategory],
    [...tempLastYearStoreInfoList],
    [...tempCurrentYearSotreInfoList],
    [...tempIncreaseList]
  );
  tempCategory.length = 0;
  tempLastYearStoreInfoList.length = 0;
  tempCurrentYearSotreInfoList.length = 0;
  tempIncreaseList.length = 0;
  tempCategory.push("", "부서");
  tempLastYearStoreInfoList.push(
    `${STORE[storeN][0]}`,
    `${selectedDate.year() - 1} 종합`
  );
  tempCurrentYearSotreInfoList.push("", `${selectedDate.year()} 종합`);
  tempIncreaseList.push("", "증감율");
};

const sameStoreInfo = (props) => {
  const {
    tempCategory,
    monthData,
    tempLastYearStoreInfoList,
    tempCurrentYearSotreInfoList,
    tempIncreaseList,
    index,
  } = props;
  tempCategory.push(monthData[1][index]["ptName"]);
  tempLastYearStoreInfoList.push(monthData[1][index]["lastYear"]);
  tempCurrentYearSotreInfoList.push(monthData[1][index]["currentYear"]);
  const calculatedrate = calculateIncreaseRateVersionTwo({
    selectedDatePrice: monthData[1][index]["currentYear"] || 0,
    compareDatePrice: monthData[1][index]["lastYear"] || 0,
  });

  tempIncreaseList.push(parseFloat(calculatedrate));
};
