import { Box } from "@mui/material";
import { chartWapper } from "layouts/main/MainPageStyle";
import React from "react";
import { Bar } from "react-chartjs-2";
import { ChartOption } from "utils/ChartOption";

const MonthChart = (props) => {
  const { chartData } = props;

  return (
    <Box
      sx={{
        ...chartWapper,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box sx={{ width: "96vw" }}>
        <Bar data={chartData} options={ChartOption} height={400} />
      </Box>
    </Box>
  );
};

export default MonthChart;
