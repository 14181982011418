import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import { Box, Button } from "@mui/material";
import { fontBold } from "styles/style";

import { totalDataTextSize } from "styles/style";
import OrderPageExtendedTable from "./OrderPageExtendedTable";
import { useCaclulatedTotalQuantity } from "./OrderPageUtils";
import { useNavigate } from "react-router-dom";

const OrderProduct = React.memo((props) => {
  const {
    selectedDatas,
    currentOrderTable,
    setCurrentOrderTable,
    originalData,
  } = props;
  const [currentTotalQ, setCurrentTotalQ] = useState(0);
  const navigate = useNavigate();
  useCaclulatedTotalQuantity({ currentOrderTable, setCurrentTotalQ });
  const getModifiedUrl = () => {
    const currentUrl = window.location.pathname; // 현재 URL 경로
    const newUrl = currentUrl.replace("/order", ""); // 'order' 부분을 제거

    return newUrl;
  };
  const movePrevePage = () => {
    navigate(getModifiedUrl(), {
      state: {
        originalData: originalData,
        selectedDatas: selectedDatas,
      },
    });
  };
  return (
    <Box>
      <TableContainer component={Paper}>
        <Box
          sx={{
            ...fontBold,

            textAlign: "center",
            fontSize: "30px",
            padding: "20px",
            marginTop: "100px",
          }}
        >
          주문 페이지
        </Box>
        <Button onClick={() => movePrevePage()}>이전페이지</Button>
        <Table
          sx={{
            borderCollapse: "separate",
            borderSpacing: 0,
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>선택</TableCell>
              <TableCell>카테고리</TableCell>
              <TableCell align="right">카테고리별 총수량</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              {/* <TableCell>총가격</TableCell> */}
            </TableRow>
          </TableHead>

          {Object.keys(currentOrderTable).length !== 0 &&
            Object.entries(currentOrderTable).map(([key, value], index) => (
              <TableBody key={key + "-" + value.pName + "-" + index}>
                <OrderPageExtendedTable
                  values={value}
                  titleIndex={index}
                  currentOrderTable={currentOrderTable}
                  setCurrentOrderTable={setCurrentOrderTable}
                />
              </TableBody>
            ))}
        </Table>
      </TableContainer>
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Box sx={totalDataTextSize}>Total Quantity: {currentTotalQ}</Box>
        {/* <Box sx={totalDataTextSize}>
          Total Amount: {currentTotalA.toFixed(2)}
        </Box> */}
      </Box>
    </Box>
  );
});

export default OrderProduct;
