import { toast } from "material-react-toastify";
import { convertDateForm } from "utils/utils";

//에러메시지를 받아서 toast 로 만드는 기능
export const userInputErrorToaster = (text) =>
  toast.warn(`${text}`, {
    position: "bottom-center",
  });

//리스트를 받아서 categoryN와 totalQt를 각각(prodName, prodQauntity)로 넣어주는 기능
export const productNameNQauntityList = (vendorInfo) => {
  vendorInfo.map((item) => {
    return { prodName: item.pName, prodQauntity: item.totalQ };
  });
};

//데이터리스트에서 중복된 값들을 삭제하는 기능
export const removeDuplicateItemFromData = (dataList, sortList) => {
  return [...new Set(dataList.map((item) => item[sortList]))];
};

//적절한 입력값이 없을때 에러메시지 (toaster)
export const errorHanglingWithoutStoreNDepart = (dataList) => {
  if (Object.keys(dataList).length === 0) {
    userInputErrorToaster("설정하신기간안에 선택된 아이템이없습니다");
  } else {
    userInputErrorToaster(
      "아직입력되지 않은 입력값이 있습니다. 기입하지 않은 입력값을 넣어주세요"
    );
  }
};

//상품에 totalQ에 따라서 sorting하는 function
export const sortByCategoryQuantity = (props) => {
  const { tableData, isAscending, setTabledata, setIsAscending } = props;

  const sortedTableData = Object.entries(tableData)
    .sort((a, b) => {
      return isAscending
        ? b[1].totalQuantity - a[1].totalQuantity
        : a[1].totalQuantity - b[1].totalQuantity;
    })
    .reduce((acc, [key, value]) => {
      acc[key] = value;
      return acc;
    }, {});
  setTabledata(sortedTableData);
  setIsAscending((prev) => {
    return !prev;
  });
};

//유저가 선택한 store, department, 시작날짜, 끝나는 날짜로 data 조회하는 function
export const getTheDataBasedOnUserInput = (props) => {
  const {
    e,
    startDate,
    endDate,
    store,
    department,
    vendorInfo,
    fetchVendorProdInfo,
    setSelectedData,
    setProdInfoloading,
  } = props;
  e.preventDefault();
  const convertedStartDate = convertDateForm(startDate);
  const convertedEndDate = convertDateForm(endDate);

  if (store === "" && department === "") {
    errorHanglingWithoutStoreNDepart(vendorInfo);
  } else {
    fetchVendorProdInfo(
      store,
      department,
      convertedStartDate,
      convertedEndDate
    );

    const selectData = {
      store: store,
      department: department,
      startDate: startDate,
      endDate: endDate,
    };
    setSelectedData(selectData);

    setProdInfoloading(false);
  }
};

export const convertDateWithCategoryTotal = (props) => {
  const { data } = props;
  const groupedData = data.reduce((acc, curr) => {
    const {
      pName,
      vp_vdpdcd,
      totalQ,
      totalA,
      prodKname,
      tPunit,
      tProd,
      prodName,
    } = curr;

    // pName 기준으로 그룹이 존재하지 않으면 초기화
    if (!acc[pName]) {
      acc[pName] = {
        pName,
        items: [],
        totalAmount: 0,
        totalQuantity: 0,
      };
    }

    if (!acc[pName].items[vp_vdpdcd]) {
      acc[pName].items[vp_vdpdcd] = {
        vp_vdpdcd,
        prodName,
        tPunit,
        tProd,
        prodKname,
        totalQ: 0,
        totalA: 0,
      };
    }

    // 하위 그룹 데이터 누적
    acc[pName].items[vp_vdpdcd].totalQ += totalQ;
    acc[pName].items[vp_vdpdcd].totalA += totalA;

    // 상위 그룹 총계 누적
    acc[pName].totalQuantity += totalQ;
    acc[pName].totalAmount += totalA;

    return acc;
  }, {});
  return { groupedData };
};

export const sortByItems = (props) => {
  const { itemDetailsList, isAscending, setIsAscending, setItemDetailsList } =
    props;
  const sortedData = itemDetailsList.items.sort((a, b) => {
    return isAscending ? b.totalQ - a.totalQ : a.totalQ - b.totalQ;
  });

  const sortedItemListObject = {
    ...itemDetailsList,
    items: sortedData,
  };

  setIsAscending((prev) => {
    return !prev;
  });

  setItemDetailsList(sortedItemListObject);
};
