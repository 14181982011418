export const mainContainer = {
  marginLeft: "auto",
  marginRight: "auto",
  width: "92vw",
};

export const flexAlignCenter = {
  display: "flex",
  alignItems: "center",
};

export const flexCenterStyles = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

export const placeAtTheCenter = {
  position: "absolute",
  top: "45%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};

export const whiteFont = {
  color: "white",
};

export const redFont = {
  color: "#cb0000",
};
export const flexStyleAround = {
  display: "flex",
  justifyContent: "space-around",
  alignItems: "center",
};

export const flexSpaceBetween = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
};

export const flexColunmAround = {
  display: "flex",
  justifyContent: "space-around",
  flexDirection: "column",
};

export const flexSpaceNoAlignSpaceBetween = {
  display: "flex",
  justifyContent: "space-between",
};

export const fontBold = {
  fontWeight: "bold",
};

export const paddingTwenty = {
  padding: "20px",
};

export const minivaviImage = {
  width: "35px",
  height: "35px",
  marginTop: "5px",
  marginLeft: "5px",
};

export const tableTopDownSize = {
  borderSpacing: "0px",
  paddingTop: "4px",
  paddingBottom: "4px",
};

export const totalDataTextSize = {
  fontWeight: "bold",
  fontSize: "18px",
  textAlign: "Right",
  marginRight: "20px",
};

export const orderConfirmModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export const dailyComponentContainer = {
  marginTop: "120px",
  position: "absolute",
  display: "flex",
  width: "98vw",
  flexDirection: "column",
};

export const dailyComponentTitle = {
  fontSize: "20px",
  fontWeight: "bold",
};
