export const ChartOption = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: "bottom",
      labels: {
        color: "#000000",
      },
    },
  },
  scales: {
    x: {
      ticks: {
        font: {
          size: (context) => {
            const width = context.chart.width;
            if (width > 1000) return 15;
            else if (width > 600) return 12;
            else if (width > 400) return 10;
            else return 8;
          },
        },
      },
    },
    y: {
      ticks: {
        font: {
          size: (context) => {
            const width = context.chart.width;
            if (width > 1000) return 15;
            else if (width > 600) return 12;
            else if (width > 400) return 10;
            else return 8;
          },
        },
      },
    },
  },
};

export const createChartDataForm = (
  label,
  categories,
  firstData,
  secondData
) => {
  // console.log(label, categories, firstData, secondData);
  return {
    labels: label,
    datasets: [
      {
        label: categories[0],
        data: firstData,
        backgroundColor: "#4caf50",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
      {
        label: categories[1],
        data: secondData,
        backgroundColor: "#2196f3",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
    ],
  };
};
