import { useCallback, useEffect } from "react";
import EmailSender from "utils/EmailSender";
import OrderedProdctExcel from "utils/OrderedProdctExcel";

export const calculateTotalWithTax1NTax2NQt = ({
  tax1,
  tax2,
  quantity,
  price,
}) => {
  return ((1 + tax1 + tax2) * quantity * price).toFixed(2);
};

export const caclulateTotalOrderAmount = (currentOrderTable, title) => {
  const calculatedTotal = currentOrderTable.reduce((total, item) => {
    return total + item[title];
  }, 0);
  return calculatedTotal;
};

export const orderByEmailWithExcel = async (currentOrderTable) => {
  const createdExcel = await OrderedProdctExcel({ data: currentOrderTable });
  EmailSender({ createdExcel: createdExcel });
};

export const useAddSelectSection = (props) => {
  const { groupedData, setCurrentOrderTable } = props;
  useEffect(() => {
    if (groupedData) {
      const addSelectSectiontoCurrentOrderItems = Object.entries(
        groupedData
      ).map(([key, value]) => {
        const updatedValue = { ...value, select: true };

        const updatedItems = Object.values(value.items).map((item) => ({
          ...item,
          select: true,
        }));

        return { ...updatedValue, items: updatedItems };
      });

      setCurrentOrderTable(addSelectSectiontoCurrentOrderItems);
    }
  }, [groupedData]);
};

export const useFilterConfirmList = (props) => {
  const { currentOrderTable, setConfirmItemsList } = props;
  useEffect(() => {
    const filtereConfimedItemList = () => {
      if (Object.keys(currentOrderTable).length !== 0) {
        const filteredList = currentOrderTable
          ?.map((group) => {
            const filteredItems = group.items.filter(
              (item) => item.select === true
            );
            return {
              ...group,
              items: filteredItems,
            };
          })
          .filter((group) => group.items.length > 0);
        setConfirmItemsList(filteredList);
      }
    };
    filtereConfimedItemList();
  }, [currentOrderTable]);
};

// 모든 아이템에 총합을 계산하는 function
export const useCaclulatedTotalQuantity = (props) => {
  const { currentOrderTable, setCurrentTotalQ } = props;
  useEffect(() => {
    const calculateTotalQuantity = () => {
      if (Object.keys(currentOrderTable).length !== 0) {
        const calculateTotalQ = currentOrderTable?.reduce((acc, group) => {
          return (
            acc +
            group.items.reduce((innerAcc, item) => {
              if (item.select === true) {
                return innerAcc + item.totalQ;
              }
              return innerAcc;
            }, 0)
          );
        }, 0);

        setCurrentTotalQ(calculateTotalQ);
      }
    };

    calculateTotalQuantity();
  }, [currentOrderTable]);
};

//카테고리 checkbox를 관리하는 function
export const useLargeToggleCheckbox = (props) => {
  const { setCurrentOrderTable } = props;
  return useCallback(
    (titleIndex) => {
      setCurrentOrderTable((prevGroup) =>
        prevGroup.map((category, idx) => {
          if (idx === titleIndex) {
            const updatedSelectStatus = !category.select;

            const updatedItems = category.items.map((item) => ({
              ...item,
              select: updatedSelectStatus,
            }));

            return {
              ...category,
              select: updatedSelectStatus,
              items: updatedItems,
            };
          }
          return category;
        })
      );
    },
    [setCurrentOrderTable]
  );
};

// 아이템이 그 카테고리에서 다 선택이 된건지 아닌지 알아보는 function
export const checkAllDetailSelectedCondition = (props) => {
  const { prevGroup, titleIndex, index } = props;
  const currentSelectedDetailCondition =
    prevGroup[titleIndex].items[index].select;

  const currentAllDetailSelectedCondition = prevGroup[titleIndex].items.every(
    (item) => item.select === currentSelectedDetailCondition
  );

  return currentAllDetailSelectedCondition;
};

//아이템 각각의 select button box를 핸들하는 function
export const smallToggleCheckbox = (props) => {
  const { titleIndex, index, setCurrentOrderTable } = props;

  setCurrentOrderTable((prevGroup) => {
    const updatedTitleWithCategoryTotalIndex = prevGroup.map(
      (category, idx) => {
        if (idx === titleIndex) {
          category.items[index] = {
            ...category.items[index],
            select: !category.items[index].select,
          };
          if (category.items[index].select) {
            prevGroup[titleIndex].totalQuantity += category.items[index].totalQ;
          } else {
            prevGroup[titleIndex].totalQuantity -= category.items[index].totalQ;
          }

          return { ...category };
        }
        return category;
      }
    );

    const currentAllDetailSelectedCondition = checkAllDetailSelectedCondition({
      prevGroup,
      titleIndex,
      index,
    });

    if (
      currentAllDetailSelectedCondition &&
      prevGroup[titleIndex].items[0].select !== prevGroup[titleIndex].select
    ) {
      return updatedTitleWithCategoryTotalIndex.map((group, index) => {
        if (index === titleIndex) {
          return {
            ...group,
            select: prevGroup[titleIndex].items[0].select,
          };
        }

        return group;
      });
    }

    if (
      !currentAllDetailSelectedCondition &&
      prevGroup[titleIndex].select === false
    ) {
      return updatedTitleWithCategoryTotalIndex.map((group, index) => {
        if (index === titleIndex) {
          return {
            ...group,
            select: true,
          };
        }

        return group;
      });
    }
    return updatedTitleWithCategoryTotalIndex;
  });
};
