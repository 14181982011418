import React, { useEffect, useState } from "react";
import {
  Routes,
  Route,
  useLocation,
  useNavigate,
  Navigate,
} from "react-router-dom";

import Login from "layouts/login/Login";
import MainPageComponent from "layouts/main/MainPageComponent";
import VendorReportPageComponent from "../vendorReport/VendorReportPageComponent";
import { Box } from "@mui/material";

import OrderPage from "layouts/order/OrderPage";
import MainPageDetailsComponent from "layouts/mainpageDetails/MainPageDetailsComponent";

import { STORE } from "globalApi/EnumType";
import { useRecoilState } from "recoil";
import { userAtom } from "atoms/userAtom";
import LoginHmart from "layouts/login/LoginHmart";

import NotFound from "error/NotFound";
import DbManager from "globalApi/DbManager";
import AlertComponent from "utils/AlertComponent";
import Signup from "layouts/signup/Signup";
import DailyComponent from "layouts/salesComparision/daily/DailyComponent";
import MonthComponent from "layouts/salesComparision/month/MonthComponent";
import DailyDetailsComponent from "layouts/salesComparision/daily/details/DailyDetailsComponent";

/* 
    라우터를 포함하고 있고 각각 URL 경로에 맞춰서 화면에 관련된 컴포넌트들을 관리해주는 컴포넌트
*/

function Authenticated() {
  const location = useLocation();
  const [userStatus, setUserStatus] = useRecoilState(userAtom);
  const navigate = useNavigate();
  // const [userInfo, setUserInfo] = useState();
  //유저에 대한 정보가 없으면 바로 로그인 페이지로
  useEffect(() => {
    const checkTokenValidation = async () => {
      const response = await DbManager.GET("/employee/user/checkCookies");

      if (response.valid) {
        const userInfoResponse = await DbManager.GET("/employee/user/info");

        if (userInfoResponse) {
          setUserStatus({
            isAuthenticated: true,
            email: userInfoResponse.email,
            mainC: userInfoResponse.mainC,
            subC: userInfoResponse.subC,
          });
        } else {
          navigate("/login");
        }
      } else {
        navigate("/login");
      }
    };
    checkTokenValidation();
  }, []);

  return (
    <Box>
      <Routes>
        <Route path="/signup" element={<Signup />} />
        <Route path="/login" element={<LoginHmart />} />
        {/* <Route path="*" element={<Navigate to="/login" />} /> */}

        {/* <Route path="*" element={<AlertComponent />} /> */}

        {userStatus.mainC === "2" && (
          <>
            <Route
              path={`/vendors/${userStatus.subC}/order`}
              element={<OrderPage />}
            />
            <Route
              path={`/vendors/${userStatus.subC}`}
              element={
                <VendorReportPageComponent
                  vendor={location.pathname.split("/")[2]}
                />
              }
            />
          </>
        )}

        {userStatus?.mainC === "1" && (
          <>
            <Route path="/" element={<MainPageComponent />} />
            <Route path="/daily" element={<DailyComponent />} />
            <Route path="/month" element={<MonthComponent />} />
            {Object.entries(STORE).map(([key, value], index) => (
              <>
                <Route
                  key={key + index + "main"}
                  path={`/details/${value[1]}`}
                  element={<MainPageDetailsComponent />}
                />
                <Route
                  key={key + index + "daily"}
                  path={`/daily/details/${value[1]}`}
                  element={<DailyDetailsComponent />}
                />
              </>
            ))}
          </>
        )}
      </Routes>
    </Box>
  );
}

export default Authenticated;
