import { Box, Table, TableBody, TableHead, TableRow } from "@mui/material";
import { StyledTableCell } from "layouts/main/MainPageStyle";
import React from "react";
import { flexCenterStyles } from "styles/style";

const DailyDetailsTableComponent = (props) => {
  const { tableData, selectedDate } = props;
  return (
    <Box sx={flexCenterStyles}>
      <Table sx={{ width: "97vw" }}>
        <TableBody>
          {tableData?.map((column, outerIndex) => (
            <TableRow
              key={column + outerIndex}
              sx={{
                borderTop: "1px solid #ccc",
                // borderRight: "1px solid #ccc",
                // borderLeft: "1px solid #ccc",
                // borderBottom: "1px solid #ccc",
              }}
            >
              {column.map((row, innerIndex) => (
                <StyledTableCell
                  key={innerIndex + row}
                  sx={{
                    color:
                      (outerIndex === 3 && innerIndex !== 0) ||
                      (outerIndex > 6 &&
                        (outerIndex - 7) % 3 === 0 &&
                        innerIndex !== 0)
                        ? typeof row === "number" && row < 0
                          ? "red"
                          : typeof row === "number" && row > 0
                          ? "blue"
                          : "inherit"
                        : "inherit",
                    borderLeft: "1px solid #ccc",
                    borderRight: "1px solid #ccc",
                    padding: "5px",
                    fontSize: "0.8vw",
                    textAlign:
                      innerIndex === 0 || outerIndex === 0 ? "center" : "right",
                  }}
                >
                  {(outerIndex === 3 && innerIndex !== 0) ||
                  (outerIndex > 6 &&
                    (outerIndex - 7) % 3 === 0 &&
                    innerIndex !== 0)
                    ? "% " + (row * 100).toFixed(0)
                    : row === ""
                    ? ""
                    : !isNaN(row)
                    ? "$ " +
                      row.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : row}
                </StyledTableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};

export default DailyDetailsTableComponent;
