import ProfileIndIcon from "@mui/icons-material/AssignmentInd";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import LoginIcon from "@mui/icons-material/Login";

export const SidebNavlist = [
  {
    icon: DashboardOutlinedIcon,
    heading: "Main",
  },
  {
    icon: AccessTimeIcon,
    heading: "Daily",
  },
  {
    icon: CalendarMonthIcon,
    heading: "Month",
  },
  // {
  //   icon: LoginIcon,
  //   heading: "Login",
  // },
];
