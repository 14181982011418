import { Box, Table, TableBody, TableRow } from "@mui/material";

import { StyledTableCell } from "layouts/main/MainPageStyle";
import React from "react";
import { flexCenterStyles } from "styles/style";

const MonthTableComponent = (props) => {
  const { tableData, selectedDate } = props;
  return (
    <Box sx={flexCenterStyles}>
      <Table sx={{ width: "97vw" }}>
        <TableBody>
          {tableData?.map((column, outerIndex) => (
            <TableRow key={column + outerIndex}>
              {column.map((row, innerIndex) => (
                <StyledTableCell
                  key={innerIndex + row}
                  sx={{
                    color:
                      outerIndex !== 0 &&
                      (outerIndex - 3) % 4 === 0 &&
                      innerIndex !== 0
                        ? typeof row === "number" && row < 0
                          ? "red"
                          : typeof row === "number" && row > 0
                          ? "blue"
                          : "inherit"
                        : "inherit",
                    borderBottom:
                      innerIndex === 0 &&
                      (outerIndex === 1 ||
                        outerIndex === 2 ||
                        (outerIndex > 3 &&
                          ((outerIndex - 4) % 4 === 0 ||
                            (outerIndex - 5) % 4 === 0 ||
                            (outerIndex - 6) % 4 === 0)))
                        ? "none"
                        : "1px solid #ccc",
                    borderTop: outerIndex === 0 ? "1px solid #ccc" : "",
                    borderLeft: "1px solid #ccc",
                    borderRight: "1px solid #ccc",
                    padding: "4px",
                    textAlign:
                      innerIndex === 0 ||
                      innerIndex === 1 ||
                      (innerIndex > 1 && outerIndex % 4 === 0)
                        ? "center"
                        : "right",
                    fontSize: "0.8vw",
                  }}
                >
                  {outerIndex !== 0 &&
                  (outerIndex - 3) % 4 === 0 &&
                  innerIndex !== 0 &&
                  innerIndex !== 1
                    ? row * 100 === 0
                      ? ""
                      : "% " + (row * 100).toFixed(0)
                    : row === ""
                    ? ""
                    : !isNaN(row)
                    ? "$ " +
                      row.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : row}
                </StyledTableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};

export default MonthTableComponent;
