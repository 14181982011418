import { Box, Typography } from "@mui/material";
import dayjs from "dayjs";
import { timePicker } from "layouts/main/MainPageStyle";
import { dateTimePicker } from "layouts/main/MainPageStyle";
import DatePickerComponent from "layouts/salesComparision/DatePickerComponent";
import TimePickerComponent from "layouts/salesComparision/TimePickerComponent";
import React, { useState } from "react";
import { flexCenterStyles } from "styles/style";

import { paddingTwenty } from "styles/style";
import { STORE } from "globalApi/EnumType";
import { useGetStoreDetailsInfo } from "api/comparison";

import { useCombineTwoObBasedOnCurrentStore } from "./MainPageDetailsComponentUtils";
import MainPageDetailsChart from "./MainPageDetailsChart";
import MainPageDetailsComparisonTable from "./MainPageDetailsComparisonTable";
import { useLocation } from "react-router-dom";
import { useConvertCodeToName } from "hooks/hook";
import { chartTitle } from "layouts/main/MainPageStyle";
import SideNavbar from "layouts/sidenavbar/SideNavbar";

const MainPageDetailsComponent = () => {
  const location = useLocation();
  const { date, startT, endT } = location.state || {};

  const initalDate = dayjs(date);
  const initalStartT = dayjs(
    `${date} ${startT.toString().padStart(2, "0")}:00`
  );
  const initalEndT = dayjs(`${date} ${endT.toString().padStart(2, "0")}:00`);

  const [selectedDate, setSelectedDate] = useState(initalDate);
  const [startTime, setStartTime] = useState(initalStartT);
  const [endTime, setEndTime] = useState(initalEndT);

  const currentUrl = window.location.pathname;
  const storeIntial = currentUrl.split("/").pop();

  const { storeNum } = useConvertCodeToName({ storeIntial });

  const {
    currentStoredetailInfo,
    lastYearStoreDetailInfo,
    storeDetailInfoIsLoading,
  } = useGetStoreDetailsInfo(storeIntial, selectedDate, startTime, endTime);

  const { currentNLastYearStoreDetailInfo } =
    useCombineTwoObBasedOnCurrentStore(
      currentStoredetailInfo,
      lastYearStoreDetailInfo
    ) || {};

  if (storeDetailInfoIsLoading) {
    return <div>Loading...</div>;
  }
  return (
    <>
      <SideNavbar />
      <Box sx={paddingTwenty}>
        <Typography sx={chartTitle}>
          Comparison Analysis of Sales - {STORE[storeNum][0]}
        </Typography>
        <Box
          sx={{
            ...flexCenterStyles,
            ...dateTimePicker,
          }}
        >
          <DatePickerComponent
            lable="Date"
            date={selectedDate}
            setSelectedDate={setSelectedDate}
          />
          <Box sx={timePicker}>
            <TimePickerComponent
              label="Start Time"
              time={startTime}
              setTime={setStartTime}
              compareTime={endTime}
              isStart={true}
            />
            <Typography>to</Typography>
            <TimePickerComponent
              label="End Time"
              time={endTime}
              setTime={setEndTime}
              compareTime={startTime}
              isStart={false}
            />
          </Box>
        </Box>
        {currentStoredetailInfo.length > 0 &&
          lastYearStoreDetailInfo.length > 0 && (
            <>
              <MainPageDetailsChart
                currentNLastYearStoreDetailInfo={
                  currentNLastYearStoreDetailInfo
                }
              />
              <MainPageDetailsComparisonTable
                currentNLastYearStoreDetailInfo={
                  currentNLastYearStoreDetailInfo
                }
              />
            </>
          )}
      </Box>
    </>
  );
};

export default MainPageDetailsComponent;
