import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { tableTopDownSize } from "styles/style";
import { sortByItems } from "./VendorReportUtils";
const VendorReportExtendedTable = (props) => {
  const { itemsList } = props;
  const [extendTableOpen, setExtendTableOpen] = useState(false);

  const [itemDetailsList, setItemDetailsList] = useState({});
  const [isAscending, setIsAscending] = useState(true);
  useEffect(() => {
    if (Object.keys(itemsList).length !== 0) {
      const deleteTitleFromTheItem = {
        ...itemsList,
        items: Object.values(Object.entries(itemsList)[1][1]),
      };

      setItemDetailsList(deleteTitleFromTheItem);
    }
  }, [itemsList]);

  // const sortByItems = () => {
  //   const sortedData = itemDetailsList.items.sort((a, b) => {
  //     return isAscending ? b.totalQ - a.totalQ : a.totalQ - b.totalQ;
  //   });

  //   const sortedItemListObject = {
  //     ...itemDetailsList,
  //     items: sortedData,
  //   };

  //   setIsAscending((prev) => {
  //     return !prev;
  //   });

  //   setItemDetailsList(sortedItemListObject);
  // };

  return (
    <>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell sx={{ ...tableTopDownSize }}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setExtendTableOpen(!extendTableOpen)}
          >
            {extendTableOpen ? (
              <KeyboardArrowUpIcon />
            ) : (
              <KeyboardArrowDownIcon />
            )}
          </IconButton>
        </TableCell>
        <TableCell sx={{ ...tableTopDownSize, textIndent: "150px" }}>
          {itemDetailsList.pName}
        </TableCell>
        <TableCell
          align="right"
          sx={{ ...tableTopDownSize, textIndent: "-350px" }}
        >
          {itemDetailsList.totalQuantity}
        </TableCell>
        <TableCell></TableCell>
        {/* <TableCell align="right" sx={tableTopDownSize}>
          {items.totalAmount.toFixed(2)}
        </TableCell> */}
        <TableCell></TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={extendTableOpen} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography
                variant="h6"
                gutterBottom
                component="div"
              ></Typography>
              <Table size="small" aria-label="purchases">
                <TableBody>
                  <TableRow>
                    <TableCell>밴더아이템코드</TableCell>
                    <TableCell>UPC 코드</TableCell>

                    <TableCell>상품명</TableCell>
                    <TableCell align="center">판매단위</TableCell>
                    <TableCell
                      align="right"
                      onClick={() =>
                        sortByItems({
                          itemDetailsList,
                          isAscending,
                          setIsAscending,
                          setItemDetailsList,
                        })
                      }
                      sx={{ cursor: "pointer" }}
                    >
                      판매량
                    </TableCell>
                  </TableRow>

                  {itemDetailsList["items"]?.map((item, index) => (
                    <TableRow key={item.vp_vdpdcd + index}>
                      <TableCell>{item.vp_vdpdcd}</TableCell>
                      <TableCell>{item.tProd}</TableCell>
                      <TableCell component="th" scope="row">
                        {item?.prodName !== ""
                          ? item?.prodName
                          : item?.prodKname}
                      </TableCell>
                      <TableCell align="center">{item.tPunit}</TableCell>
                      <TableCell align="right" sx={{ marginRight: "20px" }}>
                        {item.totalQ}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default VendorReportExtendedTable;
