import { Box } from "@mui/material";

import { chartWapper, chartContainder } from "layouts/main/MainPageStyle";

import React from "react";
import { Bar } from "react-chartjs-2";
import { ChartOption } from "utils/ChartOption";

const DailyChart = (props) => {
  const { chartData } = props;

  return (
    <Box sx={chartWapper}>
      <Box>
        <Bar data={chartData} options={ChartOption} height={400} />
      </Box>
    </Box>
  );
};

export default DailyChart;
