import React from "react";
import Logo from "../../img/logo.png";
import { SidebNavlist } from "./sidenavlist";
import LogoutIcon from "@mui/icons-material/Logout";
import { useNavigate } from "react-router-dom";
import { PAGES } from "globalApi/EnumType";
import { Box, Typography } from "@mui/material";
import {
  logoImage,
  navIcons,
  sidebar,
  sideMenuContainder,
  sideMenuItems,
} from "./SidenavBarStyle";
import { flexCenterStyles } from "styles/style";

import { useRecoilState } from "recoil";
import { userAtom } from "atoms/userAtom";
import { pageAtom } from "atoms/userAtom";
import DbManager from "globalApi/DbManager";

const SideNavbar = () => {
  const [userStatus, setUserStatus] = useRecoilState(userAtom);
  const [page, setPage] = useRecoilState(pageAtom);
  const navigate = useNavigate();

  const movePage = async (index) => {
    setPage({ pageNumber: index });

    switch (index) {
      case PAGES.DASHBOARD:
        navigate("/");
        break;
      case PAGES.DAILY:
        navigate("/daily");
        break;
      case PAGES.MONTH:
        navigate("/month");
        break;
      default:
        navigate("/");
    }
  };

  const signOutHandler = async (e) => {
    e.preventDefault();

    const logoutRequest = await DbManager.POST("/employee/user/logout");

    if (logoutRequest) {
      setUserStatus({
        isAuthenticated: false,
        email: "",
      });
      navigate("/login");
    }
  };
  return (
    <Box sx={sidebar}>
      <Box sx={flexCenterStyles}>
        {/* H-mart 관련된 아이콘 */}
        {/* <Box component="img" src={Logo} alt="logo" sx={logoImage} />
        <Typography variant="body1" sx={{ ...fontBold, ...navIcons }}>
          HMART
        </Typography> */}
      </Box>
      <Box sx={sideMenuContainder}>
        {SidebNavlist.map((item, index) => {
          return (
            <Box
              sx={{
                ...sideMenuItems.default,
                ...(page.pageNumber === index ? sideMenuItems.active : {}),
              }}
              key={item + index}
              onClick={() => movePage(index)}
            >
              {/* {console.log(page.pageNumber, index, "index")} */}
              <item.icon />
              <Typography sx={navIcons}>{item.heading}</Typography>
            </Box>
          );
        })}

        {userStatus.isAuthenticated && (
          <Box sx={sideMenuItems.default}>
            <LogoutIcon />
            <Typography sx={navIcons} onClick={(e) => signOutHandler(e)}>
              Sign out
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default SideNavbar;
