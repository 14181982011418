import React, { useState } from "react";
import QuantityPopup from "./QuantityPopup";
import { smallToggleCheckbox } from "./OrderPageUtils";

const { TableRow, Checkbox, TableCell, Button } = require("@mui/material");
const { useCallback } = require("react");

const OrderTableDetailTable = React.memo(
  ({ group, index, titleIndex, setCurrentOrderTable }) => {
    console.log(`Rendering item ${group.tProd}`);
    const [openPopup, setOpenPopup] = useState(false);
    const [quantity, setQuantity] = useState(10);

    const handlePopupOpen = () => {
      setOpenPopup(true);
    };

    const handlePopupClose = () => {
      setOpenPopup(false);
    };

    const handleSaveQuantity = (newQuantity) => {
      setQuantity(newQuantity);
    };
    const changeTheQuantity = () => {
      handlePopupOpen();
    };

    return (
      <>
        <TableRow key={group.tProd + index + group.vp_vdpdcd}>
          <TableCell align="center" sx={{ paddingBottom: 0, paddingTop: 0 }}>
            <Checkbox
              checked={group.select}
              onChange={() =>
                smallToggleCheckbox({ titleIndex, index, setCurrentOrderTable })
              }
            />
          </TableCell>
          <TableCell align="left" sx={{ paddingBottom: 0, paddingTop: 0 }}>
            {group.vp_vdpdcd}
          </TableCell>
          <TableCell>{group.tProd}</TableCell>
          <TableCell
            component="th"
            scope="row"
            sx={{ paddingBottom: 0, paddingTop: 0 }}
          >
            {group.prodName !== "" ? group.prodName : group.prodKname}
          </TableCell>
          <TableCell align="center">{group.tPunit}</TableCell>
          <TableCell align="right">{group.totalQ}</TableCell>
          <TableCell>
            <Button onClick={() => changeTheQuantity()}>수정</Button>
          </TableCell>
        </TableRow>
        <QuantityPopup
          open={openPopup}
          onClose={handlePopupClose}
          onSave={handleSaveQuantity}
          currentQuantity={group.totalQ}
          titleIndex={titleIndex}
          index={index}
          setCurrentOrderTable={setCurrentOrderTable}
        />
      </>
    );
  }
);

const OrderDetailComponent = ({
  categoryDetail,
  titleIndex,
  setCurrentOrderTable,
}) => {
  const renderRows = useCallback(() => {
    return categoryDetail.items.map((group, index) => (
      <OrderTableDetailTable
        key={group.tProd + index + group.vp_vdpdcd}
        group={group}
        titleIndex={titleIndex}
        index={index}
        setCurrentOrderTable={setCurrentOrderTable}
      />
    ));
  }, [categoryDetail.items, titleIndex]);
  return <>{renderRows()}</>;
};

export default OrderDetailComponent;
