import { styled, TableCell, TableRow } from "@mui/material";
// import { fontWeight } from "html2canvas/dist/types/css/property-descriptors/font-weight";
// import { fontBold } from "styles/style";

export const barChartProps = {
  chartType: "Bar",
  width: "100%",
  height: "100%",
  color: {
    // background: "#49a3f1",
    background: "#ffffff",
    boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
  },
};

export const achieveDefault = {
  title: "Total Sales Achievement",
  color: "#49a3f1",
};

export const marginDefault = {
  title: "Total Sales Margin",
  color: "#66BB6A",
};

export const chartTitle = {
  fontSize: "20px",
  paddingTop: "70px",
  fontWeight: "bold",
  "@media (max-width: 370px)": {
    fontSize: "5vw",
  },
};

export const comparisonTable = {
  tableLayout: "fixed",
  maxHeight: "400px",
  overflowX: "auto",
};

export const chartWapper = {
  // width: "100%",
  // height:
  // height: "200px",
  overflowX: "auto",
};

export const dateTimePicker = {
  display: "flex",
  justifyContent: "center",
  flexDirection: "row",
  gap: "10px",
  "@media (max-width: 540px)": {
    flexDirection: "column",
    alignItems: "center",
  },
};

export const timePicker = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "10px",
  "@media (max-width: 540px)": {
    marginLeft: "40px",
  },
};

// 현재 comparison 페이지(메인) 에서 테이블에서 왼쪽에 스토어가 고정되는 css

export const StyledTableCell = ({ children, isFixed, ...props }) => (
  <TableCell
    {...props}
    sx={{
      ...props.sx,
      position: isFixed ? "sticky" : "initial",
      left: isFixed ? 0 : "initial",
      backgroundColor: isFixed ? "#f4f4f4" : "initial",
      zIndex: isFixed ? 1 : "initial",
    }}
  >
    {children}
  </TableCell>
);

export const StyledTableRow = styled(TableRow, {
  shouldForwardProp: (prop) => prop !== "isClickable",
})(({ theme, isClickable }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  cursor: isClickable ? "pointer" : "default",
  "&:hover": {
    backgroundColor: isClickable ? theme.palette.action.selected : "",
  },
}));
