import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import dayjs from "dayjs";
import { Box } from "@mui/material";
import SelectButton from "buttons/SelectButton";
import DateSelector from "buttons/DateSelector";
import VendorReportTable from "./VendorReportTable";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer } from "material-react-toastify";
import "material-react-toastify/dist/ReactToastify.css";
import {
  vendorReportContainer,
  vendorButton,
  vendorSearchButtonNorderContainer,
  vendorSearchButton,
  vendorReportContainerBox,
} from "./VendorReportPageStyle";
import {
  convertDateWithCategoryTotal,
  getTheDataBasedOnUserInput,
  userInputErrorToaster,
} from "./VendorReportUtils";
import { useVendorsData } from "api/vendorReport";
import { useGetVendorsProdInfoByUserSelection } from "api/vendorReport";
import { useVendorsStoreDepartmentsData } from "api/vendorReport";
import SideNavbar from "layouts/sidenavbar/SideNavbar";

const VendorReportPageComponent = ({ vendor }) => {
  const [vendorInfo, setVendorInfo] = useState([]);
  const location = useLocation();

  const { originalData, selectedDatas } = location.state || {};
  console.log(originalData, selectedDatas);

  const [store, setStore] = useState("");

  const [prodInfoloading, setProdInfoloading] = useState(true);

  const [department, setDepartment] = useState("");
  const [startDate, setStartDate] = useState(dayjs().subtract(8, "day"));
  const [endDate, setEndDate] = useState(dayjs().subtract(1, "day"));
  const [selectedData, setSelectedData] = useState({});

  // 리액트 Hook 을 사용해서 api request에 관련된 로직을 넣어놨습니다
  const { stores, wholeData, menuloading } = useVendorsData(vendor, setStore);
  const { storesDepartmentsList } = useVendorsStoreDepartmentsData(
    store,
    wholeData,
    setDepartment
  );

  const { data, fetchVendorProdInfo } =
    useGetVendorsProdInfoByUserSelection(vendor);
  const navigate = useNavigate();
  const { groupedData } = convertDateWithCategoryTotal({ data });

  //주문페이지로 넘어갈때 선택된 아이템이없으면 toaster event 발생 아니면 다음페이지인 주문페이지로 이동
  const linkToOrderPage = (e) => {
    e.preventDefault();

    if (Object.keys(groupedData).length !== 0) {
      navigate("order", {
        state: { groupedData: groupedData, selectedData: selectedData },
      });
    } else {
      userInputErrorToaster("설정하신기간안에 선택된 아이템이없습니다");
    }
  };

  return (
    <>
      <SideNavbar />
      {console.log(store, department, startDate, endDate, selectedData)}
      <Box>
        <Box sx={vendorReportContainerBox}>
          <Box sx={vendorReportContainer}>
            {menuloading === true ? (
              <></>
            ) : (
              <Box sx={vendorButton}>
                <Box sx={vendorButton}>
                  <SelectButton
                    setData={setStore}
                    selected={store}
                    title={"Store"}
                    data={stores}
                  />
                  {storesDepartmentsList !== undefined && (
                    <SelectButton
                      setData={setDepartment}
                      selected={department}
                      title={"Department"}
                      data={storesDepartmentsList}
                    />
                  )}
                </Box>
                <DateSelector
                  setStartDate={setStartDate}
                  setEndDate={setEndDate}
                  endDate={endDate}
                  startDate={startDate}
                />
              </Box>
            )}

            <Box sx={vendorSearchButtonNorderContainer}>
              <Button
                onClick={(e) =>
                  getTheDataBasedOnUserInput({
                    e,
                    startDate,
                    endDate,
                    store,
                    department,
                    vendorInfo,
                    fetchVendorProdInfo,
                    setSelectedData,
                    setProdInfoloading,
                  })
                }
                variant="contained"
                color="success"
                sx={vendorSearchButton}
              >
                조회
              </Button>

              <ToastContainer />
              <Button
                onClick={linkToOrderPage}
                variant="contained"
                color="success"
                sx={{
                  ...vendorSearchButton,
                  marginLeft: "10px",
                }}
              >
                주문
              </Button>
            </Box>
          </Box>

          <Box>
            <VendorReportTable
              selectedData={selectedData}
              data={data}
              groupedData={groupedData}
              loading={prodInfoloading}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default VendorReportPageComponent;
