import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
// 주문할때 excel 로 export하는 기능
const OrderedProdctExcel = ({ data }) => {
  const sanitizeSheetName = (name) => {
    if (!name) return "DefaultSheet";
    return name.replace(/[\\/:*?[\]]/g, "_");
  };
  const workbook = XLSX.utils.book_new();

  data.forEach((group) => {
    const sanitizedSheetName = sanitizeSheetName(group.pName);

    const sheetData = group.items.map((item) => ({
      제품코드: item.vp_vdpdcd,
      제품명: item.prodName,
      단위: item.tPunit,
      총수량: item.totalQ,
      // 총금액: item.totalA,
    }));

    const worksheet = XLSX.utils.json_to_sheet(sheetData);

    XLSX.utils.book_append_sheet(workbook, worksheet, sanitizedSheetName);
  });

  XLSX.writeFile(workbook, "주문내역.xlsx");

  const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
  const blob = new Blob([excelBuffer], { type: "application/octet-stream" });

  saveAs(blob, "Products.xlsx");
};

export default OrderedProdctExcel;
