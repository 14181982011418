export const PAGES = {
  DASHBOARD: 0,
  DAILY: 1,
  MONTH: 2,
  LOGOUT: 3,
};

export const STORE = {
  61: ["Coquitlam", "hcq"],
  62: ["Downtown", "hdn"],
  63: ["Langley", "hll"],
  64: ["Richmond", "hrm"],
  65: ["CQcenter", "hcc"],
  66: ["POCO", "hqc"],
  67: ["Dunbar", "hdb"],
  68: ["UBC", "eub"],
};

export const USSTORE = {
  52: ["Federal Way", "hfw"],
  53: ["Lynwood", "hlw"],
  57: ["Tacoma", "htm"],
  59: ["Lynwood Gmart", "glw"],
  81: ["Bellevue", "hbe"],
  83: ["UW", "huw"],
  85: ["Redmond", "hrd"],
};

export const Days = {
  Sunday: 0,
  Monday: 1,
  Tuesday: 2,
  Wendsday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
};
