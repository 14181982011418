import dayjs from "dayjs";
import DbManager from "globalApi/DbManager";
import { useEffect, useState } from "react";
import { getOneYBeforeSameDay } from "utils/utils";
import { checkSelectedMonthIsCurrentMonth } from "utils/utils";
import {
  reformedMonthChartData,
  reformedMonthTableData,
} from "./MonthComponentUtils";
import { addEmptyValueToTableData } from "../daily/DailyPageComponentUtils";

export const useInitalSelecteNCompareDate = (selectedMonth) => {
  const [selectedSDate, setSelectedSDate] = useState();
  const [selectedEDate, setSelectedEDate] = useState();
  const [comparedSDate, setComparedSDate] = useState();
  const [comparedEDate, setComparedEDate] = useState();
  useEffect(() => {
    const checkedCurrentMonth = checkSelectedMonthIsCurrentMonth(selectedMonth);

    if (checkedCurrentMonth) {
      setSelectedEDate(dayjs().subtract(1, "day"));
      setComparedEDate(getOneYBeforeSameDay(dayjs().subtract(1, "day")));
    } else {
      setSelectedEDate(selectedMonth.endOf("month"));
      setComparedEDate(getOneYBeforeSameDay(selectedMonth.endOf("month")));
    }
    setSelectedSDate(selectedMonth.startOf("month"));
    setComparedSDate(getOneYBeforeSameDay(selectedMonth.startOf("month")));
  }, [selectedMonth]);

  return { selectedSDate, selectedEDate, comparedSDate, comparedEDate };
};

export const useGetStoreMonthData = (props) => {
  const {
    selectedSDate,
    selectedMonth,
    selectedEDate,
    comparedSDate,
    comparedEDate,
  } = props;
  const [storesMonthData, setStoresMonthData] = useState();
  useEffect(() => {
    if (selectedSDate && selectedEDate) {
      const reformedSelectedSDate = selectedSDate.format("YYYY-MM-DD");
      const reformedSelectedEDate = selectedEDate.format("YYYY-MM-DD");

      const getTheStoresMonthData = async () => {
        const storesMonthData = await Promise.all([
          DbManager.GET(
            `/achieve/stores/entier/sales/category/yearsTotal?selectedSDate=${reformedSelectedSDate}&selectedEDate=${reformedSelectedEDate}&comparedSDate=${comparedSDate}&comparedEDate=${comparedEDate}`
          ),
          DbManager.GET(
            `/achieve/stores/sales/category/yearsTotal?selectedSDate=${reformedSelectedSDate}&selectedEDate=${reformedSelectedEDate}&comparedSDate=${comparedSDate}&comparedEDate=${comparedEDate}`
          ),
        ]);

        setStoresMonthData(storesMonthData);
        console.log(storesMonthData, "storesMonthData");
      };
      getTheStoresMonthData();
    }
  }, [
    selectedMonth,
    selectedSDate,
    selectedEDate,
    comparedEDate,
    comparedSDate,
  ]);
  return { storesMonthData };
};

export const useReformChartData = (props) => {
  const { storesMonthData } = props;
  const [chartMonthData, setChartMonthData] = useState();
  useEffect(() => {
    if (storesMonthData) {
      const filteredData = storesMonthData[0]?.slice(0, -1);

      const chartData = reformedMonthChartData({ monthData: filteredData });

      setChartMonthData(chartData);
    }
  }, [storesMonthData]);
  return { chartMonthData };
};

export const useReformMonthTableData = (props) => {
  const { storesMonthData, selectedEDate, selectedSDate, selectedMonth } =
    props;
  const [tableMonthData, setTableMonthData] = useState();
  useEffect(() => {
    if (storesMonthData) {
      const gapSDateToEDate = selectedEDate.date() - selectedSDate.date();
      const monthTableData = reformedMonthTableData({
        monthData: storesMonthData,
        gapSDateToEDate: gapSDateToEDate,
        selectedDate: selectedMonth,
      });
      const reformedTableData = addEmptyValueToTableData({
        reformedTableData: monthTableData,
      });
      setTableMonthData(reformedTableData);
    }
  }, [storesMonthData]);
  return { tableMonthData };
};
