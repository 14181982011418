import { Box, Table, TableBody, TableRow } from "@mui/material";
import { STORE } from "globalApi/EnumType";

import { StyledTableCell } from "layouts/main/MainPageStyle";
import React from "react";
import { useNavigate } from "react-router-dom";
import { flexCenterStyles } from "styles/style";

const DailyTableComponet = (props) => {
  const { tableData, selectedDate } = props;

  const navigate = useNavigate();
  const moveDailyDetailPage = (column, outerIndex, innerIndex) => {
    // console.log(outerIndex, "outer Index");
    // console.log(innerIndex, "inner Index");
    if (outerIndex % 4 === 0 && innerIndex === 0) {
      const storeName = Object.values(STORE).find(
        (item) => item[0] === column[0]
      );
      console.log(storeName[1], "name");

      navigate(`/daily/details/${storeName[1]}`, {
        state: { selectedDate: selectedDate },
      });
    }
  };
  return (
    <Box sx={{ ...flexCenterStyles, overflowX: "auto" }}>
      <Table sx={{ width: "97vw" }}>
        <TableBody>
          {tableData?.map((column, outerIndex) => (
            <TableRow
              key={column + outerIndex}
              sx={{
                borderTop: "1px solid #ccc",
                // borderRight: "1px solid #ccc",
                // borderLeft: "1px solid #ccc",
                // borderBottom: "1px solid #ccc",
              }}
            >
              {column.map((row, innerIndex) => (
                <StyledTableCell
                  key={innerIndex + row + outerIndex}
                  sx={{
                    color:
                      outerIndex !== 0 &&
                      (outerIndex - 3) % 4 === 0 &&
                      innerIndex !== 0
                        ? typeof row === "number" && row < 0
                          ? "red"
                          : typeof row === "number" && row > 0
                          ? "blue"
                          : "inherit"
                        : "inherit",
                    borderLeft: "1px solid #ccc",
                    borderRight: "1px solid #ccc",
                    padding: "5px",
                    textAlign:
                      innerIndex === 0 ||
                      (innerIndex > 0 && outerIndex % 4 === 0)
                        ? "center"
                        : "right",
                    fontSize: "0.8vw",

                    cursor: outerIndex % 4 === 0 ? "pointer" : "default",
                    "&:hover":
                      outerIndex % 4 === 0
                        ? { backgroundColor: "#ff9800" }
                        : {},
                  }}
                  onClick={() =>
                    moveDailyDetailPage(column, outerIndex, innerIndex)
                  }
                >
                  {outerIndex !== 0 &&
                  (outerIndex - 3) % 4 === 0 &&
                  innerIndex !== 0
                    ? "% " + (row * 100).toFixed(0)
                    : row === ""
                    ? ""
                    : !isNaN(row)
                    ? "$ " +
                      row.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : row}
                </StyledTableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};

export default DailyTableComponet;
