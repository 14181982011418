import {
  Box,
  Button,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableHead,
  Typography,
} from "@mui/material";
import { orderConfirmModalStyle } from "styles/style";

const OrderConfirmModal = (props) => {
  const {
    confirmItemsList,
    modalOpen,
    setModalOpen,
    setExportExcelHandlerState,
  } = props;

  // useEffect(() => {
  //   const filtereConfimedItemList = () => {
  //     if (Object.keys(currentOrderTable).length !== 0) {
  //       const filteredList = currentOrderTable
  //         ?.map((group) => {
  //           const filteredItems = group.items.filter(
  //             (item) => item.select === true
  //           );
  //           return {
  //             ...group,
  //             items: filteredItems,
  //           };
  //         })
  //         .filter((group) => group.items.length > 0);
  //       setConfirmItemsList(filteredList);
  //     }
  //   };
  //   filtereConfimedItemList();
  // }, [currentOrderTable]);

  const handleOpen = () => setModalOpen(true);
  const handleClose = () => setModalOpen(false);

  const orderConfirmeEvent = (e) => {
    e.preventDefault();
    handleClose();
    setExportExcelHandlerState(true);
  };

  return (
    <Box>
      <Button onClick={handleOpen}></Button>
      <Modal
        keepMounted
        open={modalOpen}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box
          sx={{ ...orderConfirmModalStyle, overflowY: "auto", height: "600px" }}
        >
          <Typography
            id="keep-mounted-modal-title"
            variant="h6"
            component="h2"
            sx={{
              fontSize: "18px",
              backgroundColor: "#FF0000",
              color: "white",
              fontWeight: "bold",
            }}
          >
            입력 정보가 정확한지 확인해주세요.
          </Typography>
          <Table
            sx={{
              gap: 5,
              fontSize: "8px",
            }}
          >
            <TableHead>
              <TableCell></TableCell>
              <TableCell sx={{ padding: "8px" }}>카테고리</TableCell>
              <TableCell sx={{ padding: "8px" }}>각각의 갯수</TableCell>
              <TableCell></TableCell>
              {/* <TableCell align="center">총합</TableCell> */}
            </TableHead>
            {Object.entries(confirmItemsList)?.map(([key, value]) => (
              <TableBody>
                <TableCell></TableCell>
                <TableCell sx={{ paddingBottom: 0, paddingTop: 0 }}>
                  {value.pName}
                </TableCell>

                <TableCell
                  align="right"
                  sx={{ paddingBottom: 0, paddingTop: 0 }}
                >
                  {value.totalQuantity}
                </TableCell>
                <TableCell></TableCell>
                {/* <TableCell
                      align="right"
                      sx={{ paddingBottom: 0, paddingTop: 0 }}
                    >
                      ${value.totalAmount.toFixed(2)}
                    </TableCell> */}
              </TableBody>
            ))}
          </Table>
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
            }}
          >
            <Button
              onClick={(e) => orderConfirmeEvent(e)}
              sx={{
                color: "#FF0000",
                "&:hover": {
                  backgroundColor: "#D84315",
                  color: "white",
                },
              }}
            >
              확인
            </Button>
            <Button
              onClick={() => handleClose()}
              sx={{
                color: "#FF0000",
                "&:hover": {
                  backgroundColor: "#D84315",
                  color: "white",
                },
              }}
            >
              아니요
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default OrderConfirmModal;
