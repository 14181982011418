import dayjs from "dayjs";
import DbManager from "globalApi/DbManager";
import { useEffect, useState } from "react";
import { getOneYBeforeSameDay } from "utils/utils";
import { checkSelectedMonthIsCurrentMonth } from "utils/utils";
import {
  reformedDailyDetailsPageChartData,
  reformedDailyDetailsTableData,
} from "./DailyDetailsComponentUtils";

export const useInitialSelectedNCompareDate = (props) => {
  const { selectedMonth } = props;

  const [selectedSDate, setSelectedSDate] = useState();
  const [selectedEDate, setSelectedEDate] = useState();
  const [comparedSDate, setComparedSDate] = useState();
  const [comparedEDate, setComparedEDate] = useState();

  useEffect(() => {
    const checkedCurrentMonth = checkSelectedMonthIsCurrentMonth(selectedMonth);

    if (checkedCurrentMonth) {
      setSelectedEDate(dayjs().subtract(1, "day"));
      setComparedEDate(getOneYBeforeSameDay(dayjs().subtract(1, "day")));
    } else {
      setSelectedEDate(selectedMonth.endOf("month"));
      setComparedEDate(getOneYBeforeSameDay(selectedMonth.endOf("month")));
    }
    setSelectedSDate(selectedMonth.startOf("month"));
    setComparedSDate(getOneYBeforeSameDay(selectedMonth.startOf("month")));
  }, [selectedMonth]);
  return { selectedSDate, selectedEDate, comparedSDate, comparedEDate };
};

export const useGetDailyDetailByStore = (props) => {
  const {
    selectedSDate,
    selectedEDate,
    comparedSDate,
    comparedEDate,
    storeIntial,
  } = props;
  const [dailyDetailsData, setDailyDetailsData] = useState();

  useEffect(() => {
    if (selectedSDate) {
      const reformedSelectedSDate = selectedSDate.format("YYYY-MM-DD");
      const reformedSelectedEDate = selectedEDate.format("YYYY-MM-DD");

      const getTheStoresMonthDetails = async () => {
        const storesDailySailesDetails = await Promise.all([
          DbManager.POST(`/achieve/stores/daily-sales/details/total`, {
            selectedSDate: reformedSelectedSDate,
            selectedEDate: reformedSelectedEDate,
            comparedSDate: comparedSDate,
            comparedEDate: comparedEDate,
            store: storeIntial,
          }),
          DbManager.POST(`/achieve/stores/daily-sales/details`, {
            store: storeIntial,
            selectedSDate: reformedSelectedSDate,
            selectedEDate: reformedSelectedEDate,
          }),
          DbManager.POST(`/achieve/stores/daily-sales/details`, {
            store: storeIntial,
            selectedSDate: comparedSDate,
            selectedEDate: comparedEDate,
          }),
          DbManager.GET(
            `/achieve/store/purchase/yearTotal?storeIntial=${storeIntial}&selectedSDate=${reformedSelectedSDate}&selectedEDate=${reformedSelectedEDate}`
          ),
        ]);
        console.log(storesDailySailesDetails, "storesDailySailesDetails");
        setDailyDetailsData(storesDailySailesDetails);
      };
      getTheStoresMonthDetails();
    }
  }, [selectedSDate]);
  return { dailyDetailsData };
};

export const useReformDailyDetailChartData = (props) => {
  const { dailyDetailsData } = props;
  const [dailydetailChartDetail, setDailydetailChartDetail] = useState();

  useEffect(() => {
    if (dailyDetailsData) {
      const filteredData = dailyDetailsData[0]?.slice(0, -1);

      const reformedChartData = reformedDailyDetailsPageChartData({
        dailyDetailsData: filteredData,
      });
      setDailydetailChartDetail(reformedChartData);
    }
  }, [dailyDetailsData]);
  return { dailydetailChartDetail };
};

export const useReformDailyDetailTableData = (props) => {
  const { dailyDetailsData, selectedEDate, selectedSDate, selectedMonth } =
    props;

  const [dailyDetailTableData, setDailyDetailTableData] = useState();
  useEffect(() => {
    if (dailyDetailsData) {
      const gapSDateToEDate = selectedEDate.date() - selectedSDate.date();

      const reformedTableData = reformedDailyDetailsTableData({
        dailyDetailsData,
        selectedMonth,
        gapSDateToEDate,
      });
      setDailyDetailTableData(reformedTableData);
    }
  }, [dailyDetailsData]);
  return { dailyDetailTableData };
};
