import { Box, Typography } from "@mui/material";
import dayjs from "dayjs";
// import DbManager from "globalApi/DbManager";
import MonthPickerComponent from "layouts/salesComparision/MonthPickerComponent";
import SideNavbar from "layouts/sidenavbar/SideNavbar";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
// import { getOneYBeforeSameDay } from "utils/utils";
import DailyDetailsChart from "./DailyDetailsChart";
import {
  reformedDailyDetailsPageChartData,
  reformedDailyDetailsTableData,
} from "./DailyDetailsComponentUtils";
import DailyDetailsTableComponent from "./DailyDetailsTableComponent";
// import { useRecoilState } from "recoil";
import { pageAtom } from "atoms/userAtom";
import { dailyComponentContainer } from "styles/style";
import { usePageEffect } from "hooks/hook";
// import { checkSelectedMonthIsCurrentMonth } from "utils/utils";
import {
  useGetDailyDetailByStore,
  useInitialSelectedNCompareDate,
  useReformDailyDetailChartData,
  useReformDailyDetailTableData,
} from "./DailyDetailsComponentHooks";

const DailyDetailsComponent = () => {
  const currentUrl = window.location.pathname;
  const storeIntial = currentUrl.split("/").pop();
  const location = useLocation();
  const { selectedDate } = location.state || {};
  const convertedValidDayJsForm = dayjs(selectedDate.$d);
  const [selectedMonth, setSelectedMonth] = useState(convertedValidDayJsForm);

  // const [selectedSDate, setSelectedSDate] = useState();
  // const [selectedEDate, setSelectedEDate] = useState();
  // const [comparedSDate, setComparedSDate] = useState();
  // const [comparedEDate, setComparedEDate] = useState();

  // const [dailyDetailsData, setDailyDetailsData] = useState();
  // const [dailydetailChartDetail, setDailydetailChartDetail] = useState();
  // const [dailyDetailTableData, setDailyDetailTableData] = useState();

  usePageEffect(pageAtom, 1);
  const { selectedSDate, selectedEDate, comparedSDate, comparedEDate } =
    useInitialSelectedNCompareDate({ selectedMonth });
  // useEffect(() => {
  //   const checkedCurrentMonth = checkSelectedMonthIsCurrentMonth(selectedMonth);

  //   if (checkedCurrentMonth) {
  //     setSelectedEDate(dayjs().subtract(1, "day"));
  //     setComparedEDate(getOneYBeforeSameDay(dayjs().subtract(1, "day")));
  //   } else {
  //     setSelectedEDate(selectedMonth.endOf("month"));
  //     setComparedEDate(getOneYBeforeSameDay(selectedMonth.endOf("month")));
  //   }
  //   setSelectedSDate(selectedMonth.startOf("month"));
  //   setComparedSDate(getOneYBeforeSameDay(selectedMonth.startOf("month")));
  // }, [selectedMonth]);

  // const checkSelectedMonthIsCurrentMonth = (props) => {
  //   return props.month() === dayjs().month();
  // };
  const { dailyDetailsData } = useGetDailyDetailByStore({
    selectedSDate,
    selectedEDate,
    comparedSDate,
    comparedEDate,
    storeIntial,
  });
  // useEffect(() => {
  //   if (selectedSDate) {
  //     const reformedSelectedSDate = selectedSDate.format("YYYY-MM-DD");
  //     const reformedSelectedEDate = selectedEDate.format("YYYY-MM-DD");

  //     const getTheStoresMonthDetails = async () => {
  //       const storesDailySailesDetails = await Promise.all([
  //         DbManager.POST(`/achieve/stores/daily-sales/details/total`, {
  //           selectedSDate: reformedSelectedSDate,
  //           selectedEDate: reformedSelectedEDate,
  //           comparedSDate: comparedSDate,
  //           comparedEDate: comparedEDate,
  //           store: storeIntial,
  //         }),
  //         DbManager.POST(`/achieve/stores/daily-sales/details`, {
  //           store: storeIntial,
  //           selectedSDate: reformedSelectedSDate,
  //           selectedEDate: reformedSelectedEDate,
  //         }),
  //         DbManager.POST(`/achieve/stores/daily-sales/details`, {
  //           store: storeIntial,
  //           selectedSDate: comparedSDate,
  //           selectedEDate: comparedEDate,
  //         }),
  //         DbManager.GET(
  //           `/achieve/store/purchase/yearTotal?storeIntial=${storeIntial}&selectedSDate=${reformedSelectedSDate}&selectedEDate=${reformedSelectedEDate}`
  //         ),
  //       ]);

  //       setDailyDetailsData(storesDailySailesDetails);
  //     };
  //     getTheStoresMonthDetails();
  //   }
  // }, [selectedSDate]);
  const { dailydetailChartDetail } = useReformDailyDetailChartData({
    dailyDetailsData,
  });
  // useEffect(() => {
  //   if (dailyDetailsData) {
  //     const filteredData = dailyDetailsData[0]?.slice(0, -1);

  //     const reformedChartData = reformedDailyDetailsPageChartData({
  //       dailyDetailsData: filteredData,
  //     });
  //     setDailydetailChartDetail(reformedChartData);
  //   }
  // }, [dailyDetailsData]);
  const { dailyDetailTableData } = useReformDailyDetailTableData({
    dailyDetailsData,
    selectedEDate,
    selectedSDate,
    selectedMonth,
  });
  // useEffect(() => {
  //   if (dailyDetailsData) {
  //     const gapSDateToEDate = selectedEDate.date() - selectedSDate.date();

  //     const reformedTableData = reformedDailyDetailsTableData({
  //       dailyDetailsData,
  //       selectedMonth,
  //       gapSDateToEDate,
  //     });
  //     setDailyDetailTableData(reformedTableData);
  //   }
  // }, [dailyDetailsData]);

  return (
    <>
      {/* {console.log(dailyDetailTableData, "dailyDetailTableData")} */}
      <SideNavbar />
      <Box sx={dailyComponentContainer}>
        <Box>
          <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>
            Comparison report of sales-Daily
          </Typography>
        </Box>
        {selectedMonth && (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <MonthPickerComponent
              date={selectedMonth}
              setSelectedMonth={setSelectedMonth}
            />
          </Box>
        )}
        {/* {console.log(selectedMonth)} */}
        {dailydetailChartDetail && (
          <DailyDetailsChart chartData={dailydetailChartDetail} />
        )}
        {dailyDetailTableData && (
          <DailyDetailsTableComponent
            tableData={dailyDetailTableData}
            selectedDate={selectedMonth}
          />
        )}
      </Box>
    </>
  );
};

export default DailyDetailsComponent;
