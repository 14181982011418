import React, { useEffect, useState } from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import dayjs from "dayjs";

import { convertDateForm } from "utils/utils";
import { venderTableTitle } from "./VendorReportPageStyle";
import { whiteFont } from "styles/style";
import { flexSpaceBetween, tableTopDownSize } from "styles/style";
import { vendorContainder } from "./VendorReportPageStyle";
import VendorReportExtendedTable from "./VendorReportExtendedTable";
import { sortByCategoryQuantity } from "./VendorReportUtils";

const VendorReportTable = (props) => {
  const { selectedData, groupedData, loading } = props;
  const [tableData, setTabledata] = useState({});
  const tableCellTitle = ["카테고리", "카테고리별 총수량", ""];
  const [isAscending, setIsAscending] = useState(true);

  useEffect(() => {
    if (Object.keys(groupedData).length !== 0) {
      setTabledata(groupedData);
    }
  }, [groupedData]);

  return (
    <Box>
      <Box sx={venderTableTitle}>
        <strong>기간별 Group 매출액 집계현황</strong>
      </Box>
      <Box sx={flexSpaceBetween}>
        {Object.keys(groupedData).length !== 0 && (
          <>
            <Box sx={{ marginLeft: "8vw" }}>
              조회기간: {convertDateForm(dayjs(selectedData.startDate))} -{" "}
              {convertDateForm(dayjs(selectedData.endDate))}
            </Box>
          </>
        )}
      </Box>

      <TableContainer
        variant="soft"
        sx={{
          vendorContainder,
        }}
        component={Paper}
      >
        <Table stripe="odd" aria-label="collapsible table">
          <TableHead>
            <TableRow
              sx={{
                backgroundColor: "#129d58",
              }}
            >
              <TableCell></TableCell>
              {tableCellTitle.map((tableCell, index) => (
                <TableCell
                  align={index === 0 ? "center" : "right"}
                  key={tableCell + index}
                  sx={{
                    ...whiteFont,
                    ...tableTopDownSize,
                    ...(index === 1 && { cursor: "pointer" }),
                  }}
                  onClick={
                    index === 1
                      ? () =>
                          sortByCategoryQuantity({
                            tableData,
                            isAscending,
                            setTabledata,
                            setIsAscending,
                          })
                      : undefined
                  }
                >
                  {tableCell}
                </TableCell>
              ))}
              <TableCell></TableCell>
            </TableRow>
          </TableHead>

          {loading === true ? (
            <></>
          ) : (
            <>
              {Object.entries(tableData).map(([key, group], index) => (
                <TableBody key={key + index + group.pName}>
                  <VendorReportExtendedTable itemsList={group} />
                </TableBody>
              ))}
            </>
          )}
        </Table>
      </TableContainer>
    </Box>
  );
};

export default VendorReportTable;
