import dayjs from "dayjs";
import DbManager from "globalApi/DbManager";
import { useEffect, useState } from "react";
import { getOneYBeforeSameDay } from "utils/utils";
import {
  addEmptyValueToTableData,
  addPercentageChange,
  calculateIncreaseRate,
  reformedDailyPageChartData,
  reformedDailyTableData,
} from "./DailyPageComponentUtils";

export const useEntireStoresByCategory = () => {
  const initalDate = dayjs().subtract(1, "day");
  const [selectedDate, setSelectedDate] = useState(initalDate);
  const [comparisonDate, setComparisonDate] = useState();
  const [dailyData, setDailyData] = useState();
  useEffect(() => {
    const oneYearBeforeSameDay = getOneYBeforeSameDay(selectedDate);
    setComparisonDate(oneYearBeforeSameDay);

    const stringVersionSelectedDate = selectedDate.format("YYYY-MM-DD");

    const getSelectedDataWithLastYear = async () => {
      const getDailyData = await DbManager.GET(
        `/achieve/stores/day-sales?selectedDate=${stringVersionSelectedDate}&comparedDate=${oneYearBeforeSameDay}`
      );

      setDailyData(getDailyData);
    };
    getSelectedDataWithLastYear();
  }, [selectedDate]);

  return { selectedDate, setSelectedDate, comparisonDate, dailyData };
};

export const useReformedDailyChart = (dailyData) => {
  const [chartDataForm, setChartDataform] = useState();
  useEffect(() => {
    if (dailyData !== undefined) {
      const reformedDailyData = reformedDailyPageChartData({ dailyData });
      setChartDataform(reformedDailyData);
    }
  }, [dailyData]);
  return { chartDataForm };
};

export const useReformedTable = (dailyData, selectedDate, comparisonDate) => {
  const [tableData, setTableData] = useState();
  useEffect(() => {
    if (dailyData !== undefined) {
      const reformedTableData = reformedDailyTableData({
        dailyData,
        selectedDate,
        comparisonDate,
      });

      const calucatedIncreaseRate = calculateIncreaseRate({
        reformedTableData,
      });
      const dailyTableData = addPercentageChange({
        reformedTableData,
        calucatedIncreaseRate,
      });
      const addEmptyValues = addEmptyValueToTableData({
        reformedTableData: dailyTableData,
      });
      setTableData(addEmptyValues);
    }
  }, [dailyData]);

  return { tableData };
};
