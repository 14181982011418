import { STORE } from "globalApi/EnumType";
import { createChartDataForm } from "utils/ChartOption";

export const createArrayWithOneItemFromObject = (object, title) => {
  const filteredItemArray = Object.values(object).flatMap((record) =>
    Object.entries(record)
      .filter(([key]) => key.includes(`${title}`))
      .map(([_, value]) => value)
  );
  return filteredItemArray;
};

export const reformedMainPageChartData = (props) => {
  const { dailyTotalSales } = props;

  const store = Object.keys(dailyTotalSales).map(
    (key) => STORE[parseInt(key)][0]
  );

  const currentMAverage = createArrayWithOneItemFromObject(
    dailyTotalSales,
    "currentMAverage"
  );

  const lastYearAverage = createArrayWithOneItemFromObject(
    dailyTotalSales,
    "lastYearAverage"
  );

  const categories = ["당월평균", "전년평균"];

  const chartData = createChartDataForm(
    store,
    categories,
    currentMAverage,
    lastYearAverage
  );
  return chartData;
};
