import { STORE } from "globalApi/EnumType";
import { createArrayWithOneItemFromObject } from "layouts/main/MainPageUtils";
import { createChartDataForm } from "utils/ChartOption";
import { calculateIncreaseRateVersionTwo } from "utils/utils";
import { formatDateToShort } from "utils/utils";

export const reformedDailyPageChartData = (props) => {
  const { dailyData } = props;

  const store = Array.from(
    new Set(
      Object.entries(dailyData)?.map(
        ([key, value]) => STORE[parseInt(value["store"])][0]
      )
    )
  );
  const filteredArray = dailyData?.filter((item) => item.ptName === "Total");
  const currentYTotal = createArrayWithOneItemFromObject(
    filteredArray,
    "currentDayTotal"
  );

  const prevYTotal = createArrayWithOneItemFromObject(
    filteredArray,
    "prevDayTotal"
  );
  const categories = ["현재날짜총합", "전년날짜총합"];
  const chartData = createChartDataForm(
    store,
    categories,
    currentYTotal,
    prevYTotal
  );
  return chartData;
};

export const reformedDailyTableData = (props) => {
  const { dailyData, selectedDate, comparisonDate } = props;

  let result = [];

  const convertedSelectedDate = formatDateToShort(selectedDate);
  const convertedComparisonDate = formatDateToShort(comparisonDate);

  const uniqueStores = [...new Set(dailyData.map((item) => item.store))];

  for (let i = 0; i < uniqueStores.length; i++) {
    const filteredTitleData = dailyData.filter(
      (item) => item.store === uniqueStores[i]
    );

    const filteredCurrentYDataByCate = dailyData
      .filter((item) => item.store === uniqueStores[i])
      .map((item) => item.currentDayTotal);
    const filteredLastYDataByCate = dailyData
      .filter((item) => item.store === uniqueStores[i])
      .map((item) => item.prevDayTotal);

    const ptNames = filteredTitleData.map((item) => item.ptName);
    result.push([STORE[uniqueStores[i]][0], ...ptNames]);
    result.push([convertedComparisonDate, ...filteredLastYDataByCate]);
    result.push([convertedSelectedDate, ...filteredCurrentYDataByCate]);
  }
  // console.log(result);
  return result;
};

export const addEmptyValueToTableData = (props) => {
  const { reformedTableData } = props;
  let maxLength = Math.max(...reformedTableData.map((arr) => arr.length));

  for (let i = 0; i < reformedTableData.length; i++) {
    while (reformedTableData[i].length < maxLength) {
      reformedTableData[i].push("");
    }
  }
  return reformedTableData;
};

export const calculateIncreaseRate = (props) => {
  const { reformedTableData } = props;
  const differenceRow = [];
  const length = reformedTableData.length / 3;
  for (let i = 0; i < length; i++) {
    const rowDifference = ["증감율"];
    for (let j = 0; j < reformedTableData[i * 3].length; j++) {
      if (
        typeof reformedTableData[i * 3 + 2][j] === "number" &&
        typeof reformedTableData[i * 3 + 1][j] === "number"
      ) {
        const firstValue = reformedTableData[i * 3 + 1][j];
        const secondValue = reformedTableData[i * 3 + 2][j];
        if (firstValue <= 0) {
          rowDifference.push(-1);
        } else if (secondValue <= 0) {
          rowDifference.push(1);
        } else {
          const cacluatedValue = calculateIncreaseRateVersionTwo({
            selectedDatePrice: secondValue,
            compareDatePrice: firstValue,
          });
          rowDifference.push(parseFloat(cacluatedValue));
        }

        // else if (firstValue > secondValue) {
        //   const diff = firstValue - secondValue;
        //   const cacluatedValue = (diff / secondValue).toFixed(2);
        //   rowDifference.push(parseFloat(cacluatedValue));
        // } else {
        //   const diff = secondValue - firstValue;
        //   rowDifference.push(-(diff / firstValue).toFixed(2));
        // }
      }
    }
    differenceRow.push(rowDifference);
  }
  return differenceRow;
};

export const addPercentageChange = (props) => {
  const { reformedTableData, calucatedIncreaseRate } = props;
  const addedPercentageChangeData = [];

  for (let i = 0; i < calucatedIncreaseRate.length; i++) {
    addedPercentageChangeData.push(reformedTableData[i * 3]);
    addedPercentageChangeData.push(reformedTableData[i * 3 + 1]);
    addedPercentageChangeData.push(reformedTableData[i * 3 + 2]);
    addedPercentageChangeData.push(calucatedIncreaseRate[i]);
  }
  return addedPercentageChangeData;
};
