import { Box, Typography } from "@mui/material";
import React from "react";
import DatePickerComponent from "../DatePickerComponent";
import dayjs from "dayjs";
import SideNavbar from "layouts/sidenavbar/SideNavbar";
// import { paddingTwenty } from "styles/style";
// import DbManager from "globalApi/DbManager";
import DailyChart from "./DailyChart";
import DailyTableComponet from "./DailyTableComponet";
// import { getOneYBeforeSameDay } from "utils/utils";
// import {
//   addEmptyValueToTableData,
//   addPercentageChange,
//   calculateIncreaseRate,
//   reformedDailyPageChartData,
//   reformedDailyTableData,
// } from "./DailyPageComponentUtils";
// import utc from "dayjs/plugin/utc";
// import { useRecoilState } from "recoil";
import { pageAtom } from "atoms/userAtom";
// import { usePageEffect } from "./DailyComponentCustomHooks";
import { dailyComponentContainer } from "styles/style";
import { dailyComponentTitle } from "styles/style";
import { usePageEffect } from "hooks/hook";
import {
  useEntireStoresByCategory,
  useReformedDailyChart,
  useReformedTable,
} from "./DailyComponentHooks";
const DailyComponent = () => {
  // dayjs.extend(utc);
  // const initalDate = dayjs().subtract(1, "day");
  // const [selectedDate, setSelectedDate] = useState(initalDate);
  // const [dailyData, setDailyData] = useState();
  // const [comparisonDate, setComparisonDate] = useState();
  // const [chartDataForm, setChartDataform] = useState();
  // const [tableData, setTableData] = useState();

  usePageEffect(pageAtom, 1);

  // useEffect(() => {
  //   const oneYearBeforeSameDay = getOneYBeforeSameDay(selectedDate);
  //   setComparisonDate(oneYearBeforeSameDay);

  //   const stringVersionSelectedDate = selectedDate.format("YYYY-MM-DD");

  //   const getSelectedDataWithLastYear = async () => {
  //     const getDailyData = await DbManager.GET(
  //       `/achieve/stores/day-sales?selectedDate=${stringVersionSelectedDate}&comparedDate=${oneYearBeforeSameDay}`
  //     );

  //     setDailyData(getDailyData);
  //   };
  //   getSelectedDataWithLastYear();
  // }, [selectedDate]);
  const { selectedDate, setSelectedDate, comparisonDate, dailyData } =
    useEntireStoresByCategory();

  const { chartDataForm } = useReformedDailyChart(dailyData);

  // useEffect(() => {
  //   if (dailyData !== undefined) {
  //     const reformedDailyData = reformedDailyPageChartData({ dailyData });
  //     setChartDataform(reformedDailyData);
  //   }
  // }, [dailyData]);
  const { tableData } = useReformedTable(
    dailyData,
    selectedDate,
    comparisonDate
  );
  // useEffect(() => {
  //   if (dailyData !== undefined) {
  //     const reformedTableData = reformedDailyTableData({
  //       dailyData,
  //       selectedDate,
  //       comparisonDate,
  //     });

  //     const calucatedIncreaseRate = calculateIncreaseRate({
  //       reformedTableData,
  //     });
  //     const dailyTableData = addPercentageChange({
  //       reformedTableData,
  //       calucatedIncreaseRate,
  //     });
  //     const addEmptyValues = addEmptyValueToTableData({
  //       reformedTableData: dailyTableData,
  //     });
  //     setTableData(addEmptyValues);
  //   }
  // }, [dailyData]);

  return (
    <>
      <SideNavbar />
      <Box sx={dailyComponentContainer}>
        <Box>
          <Typography sx={dailyComponentTitle}>
            Comparison report of sales-Daily
          </Typography>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <DatePickerComponent
            date={selectedDate}
            setSelectedDate={setSelectedDate}
          />
        </Box>
        {chartDataForm !== undefined && (
          <DailyChart chartData={chartDataForm} />
        )}
        <DailyTableComponet tableData={tableData} selectedDate={selectedDate} />
      </Box>
    </>
  );
};

export default DailyComponent;
