import { Box, Button, TextField } from "@mui/material";
import DbManager from "globalApi/DbManager";
import { loginForm } from "layouts/login/LoginStyle";
import { userInput } from "layouts/login/LoginStyle";
import { loginButton } from "layouts/login/LoginStyle";
import { loginLabel } from "layouts/login/LoginStyle";
import React, { useState } from "react";
import { flexCenterStyles } from "styles/style";
import { placeAtTheCenter } from "styles/style";

const Signup = () => {
  const initalErrorMessage = {
    userEmail: "",
    name: "",
    employeeNumber: "",
  };

  const [userEmail, setUseremail] = useState("");
  const [employeeNumber, setEmployeeNumber] = useState("");
  const [name, setName] = useState("");
  const [errorMessage, setErrorMessage] = useState(initalErrorMessage);

  const handleSignUp = async (event) => {
    event.preventDefault();
    if (!emailPattern.test(userEmail)) {
      setErrorMessage((prev) => {
        return { ...prev, userEmail: "invalid user email" };
      });
      return;
    }

    const signupReqeust = DbManager.POST("/email/signup-request", {
      employeeNumber: employeeNumber,
      email: userEmail,
      name: name,
    });
    if (signupReqeust) {
      alert("Sign up 요청이 정상적으로 완료되었습니다.");
    } else {
      alert("적절하지 않은 요청으로 다시 요청해주세요.");
    }
  };

  const handleEnterEvent = (e) => {
    if (e.key === "Enter") {
      handleSignUp(e);
    }
  };

  const handleIputChange = (event) => {
    const { name, value } = event.target;

    if (name === "email") {
      setUseremail(value.trim());
    } else if (name === "employeeNumber") {
      setEmployeeNumber(value.trim());
    } else if (name === "name") {
      setName(value.trim());
    }
  };
  //   const handleInputChange = (e) => {
  //     const value = e.target.value;
  //     setUseremail(value);
  //   };
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return (
    <Box
      sx={{
        ...placeAtTheCenter,
      }}
    >
      <Box sx={{ ...flexCenterStyles, ...loginLabel }}>Sign Up</Box>
      <Box sx={{ ...flexCenterStyles, ...loginForm }}>
        <TextField
          InputProps={{ sx: userInput }}
          type="employeeNumber"
          name="employeeNumber"
          placeholder="employeeNumber"
          value={employeeNumber}
          onChange={handleIputChange}
          onKeyDown={(e) => {
            handleEnterEvent(e);
          }}
          error={errorMessage.employeeNumber === ""}
          helperText={errorMessage.employeeNumber}
        />
        <TextField
          InputProps={{ sx: userInput }}
          type="email"
          name="email"
          placeholder="email"
          value={userEmail}
          onChange={handleIputChange}
          onKeyDown={(e) => {
            handleEnterEvent(e);
          }}
          error={errorMessage.userEmail === ""}
          helperText={errorMessage.userEmail}
        />
        <TextField
          InputProps={{ sx: userInput }}
          type="text"
          name="name"
          placeholder="name"
          value={name}
          onChange={handleIputChange}
          onKeyDown={(e) => {
            handleEnterEvent(e);
          }}
          error={errorMessage.name === ""}
          helperText={errorMessage.name}
        />

        <Button
          onClick={handleSignUp}
          sx={{ ...flexCenterStyles, ...loginButton }}
        >
          SignUp request
        </Button>
      </Box>
    </Box>
  );
};

export default Signup;
