import { Box, Typography } from "@mui/material";
import dayjs from "dayjs";
// import DbManager from "globalApi/DbManager";
import React, { useEffect, useState } from "react";
import MonthPickerComponent from "../MonthPickerComponent";
import SideNavbar from "layouts/sidenavbar/SideNavbar";
// import { getOneYBeforeSameDay } from "utils/utils";
import MonthChart from "./MonthChart";
// import {
//   reformedMonthChartData,
//   reformedMonthTableData,
// } from "./MonthComponentUtils";
import MonthTableComponent from "./MonthTableComponent";
// import { addEmptyValueToTableData } from "../daily/DailyPageComponentUtils";
import { pageAtom } from "atoms/userAtom";
import { usePageEffect } from "hooks/hook";
import { dailyComponentContainer } from "styles/style";
import {
  useGetStoreMonthData,
  useInitalSelecteNCompareDate,
  useReformChartData,
  useReformMonthTableData,
} from "./MonthComponentHooks";

const MonthComponent = () => {
  const monthInital = dayjs();
  const [selectedMonth, setSelectedMonth] = useState(monthInital);

  // const [selectedSDate, setSelectedSDate] = useState();
  // const [selectedEDate, setSelectedEDate] = useState();
  // const [comparedSDate, setComparedSDate] = useState();
  // const [comparedEDate, setComparedEDate] = useState();
  // const [storesMonthData, setStoresMonthData] = useState();
  // const [chartMonthData, setChartMonthData] = useState();
  // const [tableMonthData, setTableMonthData] = useState();

  usePageEffect(pageAtom, 2);
  const { selectedSDate, selectedEDate, comparedSDate, comparedEDate } =
    useInitalSelecteNCompareDate(selectedMonth);

  // useEffect(() => {
  //   const checkedCurrentMonth = checkSelectedMonthIsCurrentMonth(selectedMonth);

  //   if (checkedCurrentMonth) {
  //     setSelectedEDate(dayjs().subtract(1, "day"));
  //     setComparedEDate(getOneYBeforeSameDay(dayjs().subtract(1, "day")));
  //   } else {
  //     setSelectedEDate(selectedMonth.endOf("month"));
  //     setComparedEDate(getOneYBeforeSameDay(selectedMonth.endOf("month")));
  //   }
  //   setSelectedSDate(selectedMonth.startOf("month"));
  //   setComparedSDate(getOneYBeforeSameDay(selectedMonth.startOf("month")));
  // }, [selectedMonth]);

  // const checkSelectedMonthIsCurrentMonth = (props) => {
  //   return props.month() === dayjs().month();
  // };

  const { storesMonthData } = useGetStoreMonthData({
    selectedSDate,
    selectedMonth,
    selectedEDate,
    comparedSDate,
    comparedEDate,
  });

  // useEffect(() => {
  //   if (selectedSDate && selectedEDate) {
  //     const reformedSelectedSDate = selectedSDate.format("YYYY-MM-DD");
  //     const reformedSelectedEDate = selectedEDate.format("YYYY-MM-DD");

  //     const getTheStoresMonthData = async () => {
  //       const storesMonthData = await Promise.all([
  //         DbManager.GET(
  //           `/achieve/stores/entier/sales/category/yearsTotal?selectedSDate=${reformedSelectedSDate}&selectedEDate=${reformedSelectedEDate}&comparedSDate=${comparedSDate}&comparedEDate=${comparedEDate}`
  //         ),
  //         DbManager.GET(
  //           `/achieve/stores/sales/category/yearsTotal?selectedSDate=${reformedSelectedSDate}&selectedEDate=${reformedSelectedEDate}&comparedSDate=${comparedSDate}&comparedEDate=${comparedEDate}`
  //         ),
  //       ]);

  //       setStoresMonthData(storesMonthData);
  //     };
  //     getTheStoresMonthData();
  //   }
  // }, [
  //   selectedMonth,
  //   selectedSDate,
  //   selectedEDate,
  //   comparedEDate,
  //   comparedSDate,
  // ]);
  const { chartMonthData } = useReformChartData({ storesMonthData });
  // useEffect(() => {
  //   if (storesMonthData) {
  //     const filteredData = storesMonthData[0]?.slice(0, -1);

  //     const chartData = reformedMonthChartData({ monthData: filteredData });

  //     setChartMonthData(chartData);
  //   }
  // }, [storesMonthData]);
  const { tableMonthData } = useReformMonthTableData({
    storesMonthData,
    selectedEDate,
    selectedSDate,
    selectedMonth,
  });
  // useEffect(() => {
  //   if (storesMonthData) {
  //     const gapSDateToEDate = selectedEDate.date() - selectedSDate.date();
  //     const monthTableData = reformedMonthTableData({
  //       monthData: storesMonthData,
  //       gapSDateToEDate: gapSDateToEDate,
  //       selectedDate: selectedMonth,
  //     });
  //     const reformedTableData = addEmptyValueToTableData({
  //       reformedTableData: monthTableData,
  //     });
  //     setTableMonthData(reformedTableData);
  //   }
  // }, [storesMonthData]);
  return (
    <>
      {/* {console.log(tableMonthData, "tableMonthData")} */}
      <SideNavbar />
      <Box sx={dailyComponentContainer}>
        <Box>
          <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>
            Comparison report of sales-Total
          </Typography>
        </Box>
        {selectedMonth && (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <MonthPickerComponent
              date={selectedMonth}
              setSelectedMonth={setSelectedMonth}
            />
          </Box>
        )}
        {chartMonthData && <MonthChart chartData={chartMonthData} />}
        {tableMonthData && <MonthTableComponent tableData={tableMonthData} />}
      </Box>
      ;
    </>
  );
};

export default MonthComponent;
