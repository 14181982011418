import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

const QuantityPopup = ({
  open,
  onClose,
  onSave,
  currentQuantity,
  setCurrentOrderTable,
  titleIndex,
  index,
}) => {
  const [quantity, setQuantity] = useState(currentQuantity);

  const handleQuantityChange = (event) => {
    let value = event.target.value;

    if (value === "" || /^[0-9]+$/.test(value)) {
      setQuantity(value);
    }
  };

  const updateTotalQuantity = () => {
    if (quantity !== "") {
      setCurrentOrderTable((prevGroup) => {
        let prevTotal = parseInt(prevGroup[titleIndex].items[index].totalQ);

        return prevGroup.map((group, groupIndex) =>
          groupIndex === titleIndex
            ? {
                ...group,
                totalQuantity:
                  parseInt(group.totalQuantity) -
                  prevTotal +
                  parseInt(quantity),
                items: group.items.map((item, itemIndex) =>
                  itemIndex === index
                    ? { ...item, totalQ: parseInt(quantity) }
                    : item
                ),
              }
            : group
        );
      });
    } else {
      alert("입력하신값이 올바르지 않습니다. 다시한번 확인해주세요");
    }

    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>수량 수정</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="수량"
          type="number"
          fullWidth
          variant="standard"
          value={quantity}
          onChange={handleQuantityChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          취소
        </Button>
        <Button onClick={updateTotalQuantity} color="primary">
          저장
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default QuantityPopup;
