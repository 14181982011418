import dayjs from "dayjs";
import { DateTime } from "luxon";

//날짜를 YYYY-MM-DD의 형식으로 만드는 기능
export const convertDateForm = (date) => {
  return date.format("YYYY-MM-DD");
};

//Date 타입이 아닌 format을가진 날짜를 변경후 특정한 format으로 만드는 기능
export const formatDateToShort = (date) => {
  return dayjs(new Date(date)).format("YYYY-MM-DD");
};
//각 아이템의 총갯수를 계산하는 기능
export const accumulateByItems = (data) => {
  return data.reduce((acc, item) => {
    const { sub_c_name, price, tax1 = 0.0, tax2 = 0.0, qt } = item;

    if (!acc[sub_c_name]) {
      acc[sub_c_name] = {
        categoryN: sub_c_name,
        totalPrice: price,
        totalTax1: tax1,
        totalTax2: tax2,
        totalQt: qt,
      };
    } else {
      acc[sub_c_name].totalPrice += price;
      acc[sub_c_name].totalQt += qt;
    }

    return acc;
  }, {});
};

//계산된 각아이템과 총갯수를 모두 더하는 기능
export const cacluateTotals = (accumulateByItem) => {
  return Object.values(accumulateByItem).reduce(
    (acc, { totalPrice, totalTax1, totalTax2, totalQt }) => {
      // console.log(accumulateByItem);
      acc.totalRevenue =
        (acc.totalRevenue || 0) +
        totalPrice * totalQt * (1 + totalTax1 + totalTax2);
      acc.totalQuantity = (acc.totalQuantity || 0) + totalQt;
      return acc;
    },
    { totalRevenue: 0, totalQuantity: 0 }
  );
};

// 태평양 시간대의 현재 시간과 요일을 가지고 같은 달의 같은 요일에 대한 리스트를 구하는 기능
export const sameDaysDatList = (pacificDate, day, loopCurrentMonth) => {
  const dateList = [];

  const currentMonth = pacificDate.getMonth();
  const nextMonth = pacificDate.getMonth() + 1;
  const year = pacificDate.getFullYear();
  const lastDateOfMonth = new Date(year, nextMonth, 0);
  const loopCondition = loopCurrentMonth
    ? pacificDate.getDate()
    : lastDateOfMonth.getDate();

  for (let i = 1; i <= loopCondition; i++) {
    let currentDate = new Date(year, currentMonth, i);
    if (currentDate.getDay() === day) {
      dateList.push(currentDate.toISOString().split("T")[0]);
    }
  }
  return dateList;
};

// 리스트에서 이름만 가지고 오는 기능
export const getProductNamesList = ({ list, name }) => {
  return list.map((item) => item[name]);
};

// 현재 날짜가 몇번째 주의 몇번째 요일인지 계산하는 기능
export const getWeekNumberOfDayInMonth = (date) => {
  const dayofWeek = date.getDay();
  const month = date.getMonth();
  const year = date.getFullYear();
  let weekCount = 0;

  const firstDayOfMonth = new Date(year, month, 1);
  const firstDayOfWeek = firstDayOfMonth.getDay();

  if (firstDayOfWeek !== 0) {
    weekCount = 1;
  }

  for (let day = 1; day <= date.getDate(); day++) {
    const currentDate = new Date(year, month, day);
    const currentDayOfWeek = currentDate.getDay();

    if (currentDayOfWeek === 0) {
      weekCount++;
    }

    if (currentDate.getTime() === date.getTime()) {
      break;
    }
  }

  return { weekCount: weekCount, dayOfWeek: dayofWeek };
};

export const formatDateToPacificTime = (selectedDate) => {
  const utcDate = DateTime.fromJSDate(selectedDate, { zone: "utc" });

  const pacificDate = utcDate.setZone("America/Los_Angeles", {
    keepLocalTime: true,
  });

  return selectedDate.format("YYYY-MM-DD");
};

export const getOneYBeforeSameDay = (current) => {
  const today = new Date(current);
  // console.log(today, "today");
  const lastYearDate = new Date(today);
  lastYearDate.setFullYear(today.getFullYear() - 1);
  const targetWeekday = today.getDay();

  while (lastYearDate.getDay() !== targetWeekday) {
    lastYearDate.setDate(lastYearDate.getDate() + 1);
  }

  return lastYearDate;
};

// 증감율 구하는 function
export const calculateIncreaseRateVersionTwo = (props) => {
  const { selectedDatePrice, compareDatePrice } = props;

  if (selectedDatePrice === 0 && compareDatePrice === 0) return 0;
  if (selectedDatePrice === 0) return -1;

  if (compareDatePrice === 0) return 1;
  return selectedDatePrice > compareDatePrice
    ? ((selectedDatePrice - compareDatePrice) / compareDatePrice).toFixed(2)
    : -((compareDatePrice - selectedDatePrice) / selectedDatePrice).toFixed(2);
};

//현재날짜선택된 날짜가 이번달인지 아닌지 확인하는 function
export const checkSelectedMonthIsCurrentMonth = (props) => {
  return props.month() === dayjs().month();
};
